import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';

export const handlePaymentApprovedFields = ({
  formMode,
  claimStatus,
  userAccess,
  AppModule,
}) => {
  const { Constants } = AppModule;
  const { CLAIM_STATUS, COMPANY_TYPE } = Constants;
  if (
    formMode === DYNAMIC_FORM_MODE.EDIT &&
    userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR &&
    claimStatus === CLAIM_STATUS.PAR
  ) {
    return false;
  }

  return true;
};

export const handlePAREditMode = ({
  formMode,
  claimStatus,
  userAccess,
  AppModule,
}) => {
  const { Constants } = AppModule;
  const { CLAIM_STATUS, COMPANY_TYPE } = Constants;
  if (
    formMode === DYNAMIC_FORM_MODE.EDIT &&
    userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR &&
    claimStatus === CLAIM_STATUS.PAR
  ) {
    return true;
  }

  return false;
};

export const showAddButtonForParts = ({
  formMode,
  claimStatus,
  userAccess,
  AppModule,
}) => !handlePAREditMode({ formMode, claimStatus, userAccess, AppModule });

export const readOnly = () => true;
