import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FormValue, useStore } from '@servicexcelerator/ui-design-system';
import { FormControl, InputLabel } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { getClaimPartCalculated } from '../PaymentSummary/PartsTotalWithPartsManager';

function PartsTotalCalculated(props) {
  const {
    formId,
    itemsArrayIndex,
    decimal = 0,
    localeId,
    label,
    AppModule,
  } = props;

  const { Constants } = AppModule;
  const { CLAIM_STATUS } = Constants;

  const [result, setResult] = useState('');
  const store = useStore();

  useEffect(() => {
    const currentClaimStatus =
      store.domain.formState.formState[formId]?.claimStatus?.key;

    const currentValues = store.domain.formState.getState(formId);

    if (Array.isArray(currentValues?.claimParts)) {
      const currentPartRow = currentValues?.claimParts[itemsArrayIndex];
      if (currentClaimStatus && currentClaimStatus !== CLAIM_STATUS.INC) {
        const withDecimalValue = Number.isNaN(currentPartRow?.totalRequested)
          ? currentPartRow?.totalRequested
          : parseFloat(currentPartRow?.totalRequested).toFixed(decimal);
        setResult(`$${withDecimalValue}`);
      } else {
        let calculatedValue = getClaimPartCalculated(currentPartRow, decimal);
        if (calculatedValue || calculatedValue === 0) {
          calculatedValue = parseFloat(calculatedValue).toFixed(decimal);
          setResult(`$${calculatedValue}`);
        } else {
          setResult('');
        }
      }
    } else {
      setResult('');
    }
  }, [
    JSON.stringify(store.domain.formState.formState[formId]?.claimParts),
    store.domain.formState.formState[formId]?.claimStatus?.key,
  ]);

  return (
    <FormControl fullWidth>
      <InputLabel>
        <FormattedMessage id={localeId} defaultMessage={label} />
      </InputLabel>
      <FormValue>{result}</FormValue>
    </FormControl>
  );
}

export default observer(PartsTotalCalculated);
