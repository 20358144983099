import { Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { TextField } from '@servicexcelerator/ui-design-system';

function DashboardHeader() {
  const showSearch = false;
  return (
    <Grid2 container>
      <Grid2 textAlign="left" xs>
        {showSearch && (
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid2>
      <Grid2 textAlign="right" xs>
        &nbsp;
      </Grid2>
    </Grid2>
  );
}
export default DashboardHeader;
