import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';

function getFormMode({ originalMode, claimStatus, userAccess, AppModule }) {
  const { Components, Constants } = AppModule;
  const { hasPermission } = Components;
  const { CLAIM_STATUS, COMPANY_TYPE, PERMISSIONS } = Constants;

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;
  const permissions = userAccess?.permissions || {};
  const permitted = hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions);

  if (isSA && claimStatus === CLAIM_STATUS.PAR && permitted) {
    return DYNAMIC_FORM_MODE.EDIT;
  }

  if (!isSA && claimStatus === CLAIM_STATUS.PAR && permitted) {
    return DYNAMIC_FORM_MODE.VIEW;
  }

  if (originalMode === DYNAMIC_FORM_MODE.EDIT && permitted) {
    if (claimStatus === CLAIM_STATUS.INC) {
      return DYNAMIC_FORM_MODE.EDIT;
    }
    return DYNAMIC_FORM_MODE.VIEW;
  }

  if (!permitted) {
    return DYNAMIC_FORM_MODE.VIEW;
  }

  return originalMode;
}

export default getFormMode;
