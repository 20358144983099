import { Typography } from '@mui/material';

const getFormResponseErrors = (formResponseErrors, AppModule) => {
  const { Utils } = AppModule;
  const { ErrorMessage } = Utils;

  let formErrors = formResponseErrors;
  if (formResponseErrors?.errors) {
    formErrors = formResponseErrors?.errors;
  }
  if (formErrors) {
    if (Array.isArray(formErrors) && formErrors.length > 0) {
      return formErrors?.map(item => (
        <li key={JSON.stringify(item)} style={{ marginBottom: '5px' }}>
          <Typography color="error">{ErrorMessage(item)}</Typography>
        </li>
      ));
    }
    // ajv error
    if (Object.entries(formErrors).length > 0) {
      return Object.entries(formErrors).map(([key, value]) => (
        <li key={JSON.stringify(key)} style={{ marginBottom: '5px' }}>
          <Typography color="error">
            {ErrorMessage({ ...value, name: key })}
          </Typography>
        </li>
      ));
    }
  }
  return null;
};

export default getFormResponseErrors;
