import { Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { LoadingEvent } from '@servicexcelerator/ui-design-system';
import RouteRBACCheck from '@components/Routing/RouteRBACCheck';
import ErrorPage, { ERROR_PAGE_TYPE } from '@pages/ErrorPage';
import AppDataProvider from '@components/AppDataProvider';

function ProtectedRoute({ componentProps = {}, Component }) {
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <LoadingEvent>
        <FormattedMessage
          id="AUTHENTICATING"
          defaultMessage="Authenticating..."
        />
      </LoadingEvent>
    );
  }

  if (auth?.error) {
    return <ErrorPage type={ERROR_PAGE_TYPE.AUTH_ERROR} />;
  }

  return auth.isAuthenticated ? (
    <RouteRBACCheck>
      <AppDataProvider>
        <Component {...componentProps} />
      </AppDataProvider>
    </RouteRBACCheck>
  ) : (
    <Navigate
      state={{ redirect_uri: window.location.href }}
      to={{
        pathname: '/unauthorized',
      }}
    />
  );
}

export default ProtectedRoute;
