function LinkDisplay(props, fieldName) {
  const {
    row: { original },
  } = props;

  return original[fieldName] ? (
    <a href={`/claim/view/${original[fieldName]}`}>{original[fieldName]}</a>
  ) : (
    ''
  );
}

function MediumDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toMediumDate } = Utils;

  return original[fieldName] ? toMediumDate(original[fieldName]) : '';
}

function Currency(props, fieldName) {
  const {
    row: { original },
  } = props;

  return original[fieldName] !== null
    ? `$${parseFloat(original[fieldName]).toFixed(2)}`
    : '';
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'claimNumber',
    header: formatMessage({
      id: 'CLAIM_NUMBER',
      defaultMessage: 'Claim Number',
    }),
    Cell: props => LinkDisplay(props, 'claimNumber'),
  },
  {
    accessorKey: 'serviceRequestedDate',
    header: formatMessage({
      id: 'SERVICE_DATE',
      defaultMessage: 'Service Date',
    }),
    Cell: props => MediumDateDisplay(props, 'serviceRequestedDate', AppModule),
  },
  {
    accessorKey: 'serviceProviderNumber',
    header: formatMessage({
      id: 'SERVICE_PROVIDER_NUMBER',
      defaultMessage: 'Service Provider Number',
    }),
  },
  {
    accessorKey: 'serviceProviderName',
    header: formatMessage({
      id: 'SERVICE_PROVIDER_NAME',
      defaultMessage: 'Service Provider Name',
    }),
  },
  {
    accessorKey: 'explanationOfServicePerformed',
    header: formatMessage({
      id: 'EXPLANATION_OF_SERVICE_PERFORMED',
      defaultMessage: 'Explanation of Service Performed',
    }),
  },
  {
    accessorKey: 'totalAmountApproved',
    header: formatMessage({
      id: 'TOTAL_APPROVED_AMOUNT',
      defaultMessage: 'Total Amount Approved',
    }),
    Cell: props => Currency(props, 'totalAmountApproved'),
  },
  {
    accessorKey: 'approvedDateTime',
    header: formatMessage({
      id: 'CLAIM_APPROVED_DATE',
      defaultMessage: 'Claim Approved Date',
    }),
    Cell: props => MediumDateDisplay(props, 'approvedDateTime', AppModule),
  },
  {
    accessorKey: 'serviceAdministratorServiceContractNumber',
    header: formatMessage({
      id: 'SERVICE_ADMINISTRATOR_SERVICE_CONTRACT_NUMBER',
      defaultMessage: 'Service Administrator Service Contract Number',
    }),
  },
];

export default getColumns;
