import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';

function prepareFormData({
  formData,
  claimData,
  currentFormMode,
  type,
  claimStatus,
  AppModule,
}) {
  const { Constants } = AppModule;
  const { CLAIM_STATUS } = Constants;

  const newFormData = JSON.parse(JSON.stringify(formData));

  /*
   * If Claim status is incomplete then never send amountApproved
   */
  if (claimStatus === CLAIM_STATUS.INC) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in newFormData.claimPayment) {
      if (
        Object.prototype.hasOwnProperty.call(
          newFormData.claimPayment[key],
          'amountApproved',
        )
      ) {
        delete newFormData.claimPayment[key].amountApproved;
      }
    }
  }

  newFormData?.claimParts?.forEach(item => {
    if (Object.prototype.hasOwnProperty.call(item, 'totalApproved')) {
      // eslint-disable-next-line no-param-reassign
      delete item?.totalApproved;
    }
    if (Object.prototype.hasOwnProperty.call(item, 'totalRequested')) {
      // eslint-disable-next-line no-param-reassign
      delete item?.totalRequested;
    }
  });

  let transformedFormData = {};

  if (currentFormMode === DYNAMIC_FORM_MODE.EDIT) {
    transformedFormData = {
      serviceAdministratorName: claimData?.serviceAdministrator,
      serviceProviderNumber: claimData?.serviceProvider?.serviceProviderNumber,
      serviceAdministratorClientNumber:
        claimData?.serviceAdministratorClientNumber,
      ...newFormData,
    };
    if (type === 'submit') {
      transformedFormData = {
        ...transformedFormData,
        claimNumber: claimData?.claimNumber,
      };
    }
  } else {
    // Submit data directly from new claim, edit data, update data flows
    transformedFormData = formData;
  }

  // Data not in ajv but required for the api
  // TODO: Fix this, should be part of AJV
  transformedFormData.partsOnly = false;
  transformedFormData.serviceProviderPrePaid = false;
  delete transformedFormData.brand;
  delete transformedFormData?.claimStatus;
  transformedFormData.brandCode = newFormData?.brand?.brandCode;

  return transformedFormData;
}

export default prepareFormData;
