import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { MainMenu, Page, useStore } from '@servicexcelerator/ui-design-system';
import { CreateClaimModal } from '@servicexcelerator/ui-claim-module';
import Icons from '@components/Icons';
import { PERMISSIONS } from '@constants/index';
import { useUserAccess, hasPermission } from '../UserAccessProvider';

function Layout({ children, AppModule }) {
  const { ClaimSearch, Claims, Home, NewClaim } = Icons;
  const store = useStore();
  const { formatMessage } = useIntl();
  const userAccess = useUserAccess();
  const permissions = userAccess?.permissions || {};
  const canCreateClaim = hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions);

  const menuItems = [
    {
      name: 'home',
      label: formatMessage({
        id: 'HOME_MENU',
        defaultMessage: 'Home',
      }),
      icon: Home,
      onClick: () => {
        window.location.href = '/dashboard';
      },
      hasPermission: true,
    },
    {
      name: 'claims',
      label: formatMessage({
        id: 'CLAIMS_MENU',
        defaultMessage: 'Claims',
      }),
      icon: Claims,
      hasPermission: true,
      children: [
        {
          name: 'claims-create',
          label: formatMessage({
            id: 'CREATE_NEW_CLAIM_MENU',
            defaultMessage: 'Create new claim',
          }),
          icon: NewClaim,
          onClick: () => {
            store.uiState.createClaimModal.setOpen(true);
          },
          hasPermission: canCreateClaim,
        },
        {
          name: 'claims-search',
          label: formatMessage({
            id: 'SEARCH_CLAIMS_MENU',
            defaultMessage: 'Search Claims',
          }),
          icon: ClaimSearch,
          onClick: () => {
            store.uiState.claimSearchForm.set({});
            window.location.href = '/claims/search';
          },
          hasPermission: true,
        },
      ],
    },
  ];

  return (
    <Page>
      <Grid2 container>
        <Grid2>
          <MainMenu menuItems={menuItems} logoHref="/dashboard" />
        </Grid2>
        <Grid2 xs>{children}</Grid2>
      </Grid2>
      <CreateClaimModal AppModule={AppModule} />
    </Page>
  );
}

export default observer(Layout);
