const MAX_NOTE_LENGTH = 1000;

const NOTE_ACTION = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};

const SUPPORTED_NOTES_ATTACHMENT_FILETYPES = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg'],
  'image/jpg': ['.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'application/msword': ['.doc'],
  'text/plain': ['.txt'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
};

const ATTACHMENT_TYPES = [
  {
    label: 'Proof Of Purchase',
    value: 'PROOF_OF_PURCHASE',
  },
  {
    label: 'Product Image',
    value: 'PRODUCT_IMAGE',
  },
  {
    label: 'Warranty Document',
    value: 'WARRANTY_DOCUMENT',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

const ATTACHMENT_PRIVACY = [
  {
    label: 'INTERNAL',
    value: 'Internal',
  },
  {
    label: 'EXTERNAL',
    value: 'External',
  },
];

export {
  NOTE_ACTION,
  SUPPORTED_NOTES_ATTACHMENT_FILETYPES,
  ATTACHMENT_TYPES,
  ATTACHMENT_PRIVACY,
  MAX_NOTE_LENGTH,
};
