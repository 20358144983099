import { useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import FormValue from '../FormValue';
import Required from '../Required';
import { DATE_FORMAT } from '../DatePicker';

export function ReadOnlyFormControl(props) {
  const {
    required = false,
    helperText,
    label,
    localeId = null,
    containerComponent,
    jsonSchema,
    masterData,
    control,
    name,
    formId,
    dateFormat = null,
    dataType,
    InputProps,
    defaultValue = null,
    setValue,
    getValues,
    type,
    decimal = null,
    ...rest
  } = props;

  const { formatMessage } = useIntl();
  let valueFormatter = null;

  if (InputProps) {
    if (InputProps?.startAdornment) {
      const {
        children = '',

        position = 'start',
      } = InputProps?.startAdornment || {};

      valueFormatter = value => {
        if (value) {
          if (position === 'start') {
            return `${children}${value || ''}`;
          }
          if (position === 'end') {
            return `${value || ''}${children}`;
          }
        }
        return value || '';
      };
    }
  }

  // You want the component to be non-editable but send a default value to api, if value doesnt exists
  useEffect(() => {
    if (defaultValue !== null && getValues(name) === null) {
      setValue(name, defaultValue);
    }
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field = {} }) => {
        const { value } = field;
        let newValue = value;

        if (dateFormat && value) {
          try {
            newValue = dayjs(value).format(DATE_FORMAT[dateFormat]);
          } catch {
            newValue = value;
          }
        } else if (dataType === 'boolean') {
          if (value) {
            newValue = formatMessage({ id: 'YES', defaultMessage: 'Yes' });
          } else {
            newValue = formatMessage({ id: 'NO', defaultMessage: 'No' });
          }
        } else if (dataType === 'number' || type === 'number') {
          if (decimal !== null) {
            newValue =
              value || value === 0 ? parseFloat(value).toFixed(decimal) : '';
          } else if (value === 0) {
            newValue = '0';
          }
        }

        let displayValue = newValue;

        if (valueFormatter) {
          displayValue = valueFormatter(newValue);
        }

        return (
          <FormControl {...rest}>
            {localeId && (
              <InputLabel>
                <FormattedMessage id={localeId} defaultMessage={label} />
                <Required show={required} />
              </InputLabel>
            )}
            <FormValue>{displayValue}</FormValue>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export default ReadOnlyFormControl;
