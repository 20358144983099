function traverseObject(obj, keys = []) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (key === 'message') {
        return `${keys.join('.')} - ${obj[key]}`;
      }
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Continue traversing the nested object
        keys.push(key);
        return traverseObject(obj[key], keys);
      }
    }
  }
  return null;
}

function ErrorMessage(errorObj) {
  let result = null;
  if (typeof errorObj === 'string') {
    result = errorObj;
  } else if (errorObj?.errorMessage) {
    result = errorObj.errorMessage;
  } else if (errorObj?.path) {
    result = `${errorObj.path} - ${errorObj?.errorMessage}`;
  } else if (errorObj?.name && errorObj?.type && errorObj?.message) {
    result = `${errorObj.name} - ${errorObj.message}`;
  } else if (errorObj?.message) {
    result = errorObj?.message;
  } else if (errorObj?.errorText) {
    result = errorObj?.errorText;
  } else if (errorObj?.errors && !Array.isArray(errorObj?.errors)) {
    const errorsAsObject = traverseObject(
      errorObj.errors ? errorObj.errors : errorObj,
      [],
    );
    if (errorsAsObject) {
      result = errorsAsObject;
    }
  }

  if (result) {
    return result;
  }
  return `Undefined Error: ${JSON.stringify(errorObj)}`;
}

export default ErrorMessage;
