import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  ConfirmationPopUp,
  DYNAMIC_FORM_MODE,
  DynamicForm,
  LoadingEvent,
  ScrollingProvider,
  SpecialSection,
  StackedFloatingButtons,
  useDataProvider,
  useSnackbar,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import getAdditionalComponents from './lib/getAdditionalComponents';
import {
  handlePAREditMode,
  handlePaymentApprovedFields,
  readOnly,
  showAddButtonForParts,
} from './lib/effectFunctions';
import RejectClaimModal from '../../modals/RejectClaimModal';
import UnderwriterApproveClaimModal from '../../modals/UnderwriterApproveClaimModal';
import UnderwriterRejectClaimModal from '../../modals/UnderwriterRejectClaimModal';
import ApproveClaimModal from '../../modals/ApproveClaimModal';
import HeaderSection from './Header/HeaderSection';
import getSteps from './lib/getSteps';
import getButtonsForServiceAdministrator from './lib/getButtonsForServiceAdministrator';
import getButtonsForServiceProvider from './lib/getButtonsForServiceProvider';
import getBaseClaimButtons from './lib/getBaseClaimButtons';
import submitClaim from './lib/submitClaim';
import updateClaim from './lib/updateClaim';
import createClaim from './lib/createClaim';

import getErrorBlock from './lib/getErrorBlock';
import prepareFormData from './lib/prepareFormData';
import getFormSchemas from './lib/getFormSchemas';
import getFormMode from './lib/getFormMode';

// Get Mode from Route
// Get ClaimId from Route
// On Page Load, call api to get claim Data

// On Create, Save, Submit call api and response should update the claimData
// On Create save/submit change the url
// On Update and Submit dont change the url

function getClaimIdFromRoute({ formMode, routeParam }) {
  if (
    formMode === DYNAMIC_FORM_MODE.EDIT ||
    formMode === DYNAMIC_FORM_MODE.VIEW
  ) {
    return routeParam?.claimId;
  }
  return routeParam?.claimRefId;
}

function ClaimPage({ formMode = DYNAMIC_FORM_MODE.VIEW, AppModule }) {
  const { Components, Constants } = AppModule;
  const { useUserAccess, Layout } = Components;
  const { COMPANY_TYPE } = Constants;

  const routeParam = useParams();
  const { getOne, updateOne, createOne } = useDataProvider();
  const store = useStore();
  const { formatMessage } = useIntl();
  const userAccess = useUserAccess();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const formRef = useRef();

  const [loading, setLoading] = useState(true);
  const [claimId, setClaimId] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [claimData, setClaimData] = useState({});
  const [formSchemas, setFormSchemas] = useState({});
  const [mode, setMode] = useState(formMode);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [errorBlock, setErrorBlock] = useState([]);

  const masterData = store.domain.masterData.get();

  /* Modals used in different button click events */
  const confirmationPopUpRef = useRef();
  const rejectClaimModalRef = useRef();
  const underwriteApproveClaimModalRef = useRef();
  const underwriteRejectClaimModalRef = useRef();
  const approveClaimModalRef = useRef();

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  const fetchClaimData = async claimNumber => {
    const result = await getOne(`claims/v1/claim/${claimNumber}`);
    return result?.data;
  };

  const getPageButtons = currentFormMode => {
    const CLAIM_BUTTONS = getBaseClaimButtons({
      formSubmitting,
      setFormSubmitting,
      formRef,
      claimId,
      snackbar,
      updateOne,
      confirmationPopUpRef,
      rejectClaimModalRef,
      underwriteApproveClaimModalRef,
      underwriteRejectClaimModalRef,
      approveClaimModalRef,
      formMode: currentFormMode,
      navigate,
      claimStatus: claimData?.claimStatus?.key,
      formatMessage,
      AppModule,
    });
    if (userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR) {
      return getButtonsForServiceAdministrator({
        CLAIM_BUTTONS,
        formMode: currentFormMode,
        claimStatus: claimData?.claimStatus?.key,
        userAccess,
        AppModule,
      });
    }
    return getButtonsForServiceProvider({
      CLAIM_BUTTONS,
      formMode: currentFormMode,
      claimStatus: claimData?.claimStatus?.key,
      userAccess,
      AppModule,
    });
  };

  // TODO: Fix this, effect should be per form, have global effect functions and then apply them based on form
  const effectFunctions = currentFormMode => ({
    handlePaymentApprovedFields: handlePaymentApprovedFields({
      formMode: currentFormMode,
      claimStatus: claimData?.claimStatus?.key,
      userAccess,
      AppModule,
    }),
    handlePAREditMode: handlePAREditMode({
      formMode: currentFormMode,
      claimStatus: claimData?.claimStatus?.key,
      userAccess,
      AppModule,
    }),
    readOnly: readOnly(),
    showAddButtonForParts: showAddButtonForParts({
      formMode: currentFormMode,
      claimStatus: claimData?.claimStatus?.key,
      userAccess,
      AppModule,
    }),
    isNotSA: !isSA,
  });

  const getAuthorizedAmount = async claimNumber => {
    const result = await getOne(
      `claims/v1/claim/${claimNumber}/authorizedamounts`,
    );
    return result?.data;
  };

  const additionalClaimInfo = async (
    claimResponse,
    claimIdArg,
    isCreateClaim = false,
  ) => {
    let authorizedAmounts = {};
    if (!isCreateClaim) {
      authorizedAmounts = await getAuthorizedAmount(claimIdArg);
    }
    return {
      serviceAdministratorName: claimResponse?.serviceAdministrator,
      serviceProviderNumber:
        claimResponse?.serviceProvider?.serviceProviderNumber,
      serviceAdministratorClientNumber:
        masterData.SERVICE_ADMINISTRATOR_CLIENT.find(
          item =>
            item.value === claimResponse?.serviceAdministratorClientNumber,
        )?.value,
      serviceAdministratorClientName:
        masterData.SERVICE_ADMINISTRATOR_CLIENT.find(
          item =>
            item.value === claimResponse?.serviceAdministratorClientNumber,
        )?.label,
      authorizedAmounts,
    };
  };

  /*
  Get Claim Id from Route
    - If no claim id was detected then display error and no form
  if action is to create a claim, then set claim details from store
  If action is to edit/view a claim, then fetch latest claim details using api
     - If claim info was not received then display error and no form
  Set the updated form mode based on user access, claim status
  */
  useEffect(() => {
    async function init() {
      setErrorBlock(null);
      const claimIdFromRoute = getClaimIdFromRoute({ formMode, routeParam });
      if (!claimIdFromRoute) {
        setPageError({
          errorMessage: formatMessage({
            id: 'CLAIM_ID_NOT_PROVIDED',
            defaultMessage: 'Claim id not provided',
          }),
        });
      } else {
        setLoading(true);
        setClaimId(claimIdFromRoute);
        let claimDataFound = null;

        if (formMode === DYNAMIC_FORM_MODE.CREATE) {
          const prospectiveClaimDetails =
            store.domain.claims.getProspectiveClaim(claimIdFromRoute);
          claimDataFound = prospectiveClaimDetails;
        } else {
          const claimDetails = await fetchClaimData(claimIdFromRoute);
          if (claimDetails) {
            claimDataFound = claimDetails;
          } else {
            setPageError({
              errorMessage: formatMessage(
                {
                  id: 'CLAIM_X_NOT_FOUND',
                  defaultMessage: 'Claim Id {claimId} not found',
                },
                {
                  claimId: claimIdFromRoute,
                },
              ),
            });
          }
        }

        const additionalClaimInfoData = await additionalClaimInfo(
          claimDataFound,
          claimIdFromRoute,
          formMode === DYNAMIC_FORM_MODE.CREATE,
        );

        if (claimDataFound) {
          setClaimData({
            ...claimDataFound,
            ...additionalClaimInfoData,
          });

          const newFormMode = getFormMode({
            originalMode: formMode,
            claimStatus: claimDataFound?.claimStatus?.key,
            userAccess,
            AppModule,
          });
          const formSchemasByClaimFormId = getFormSchemas(
            claimDataFound?.claimFormId,
          );
          setFormSchemas(formSchemasByClaimFormId);
          setMode(newFormMode);
          setErrorBlock(
            getErrorBlock({
              claimStatus: claimDataFound?.claimStatus?.key,
              formErrors: claimDataFound?.claimErrors,
              claimData: claimDataFound,
              AppModule,
            }),
          );
        }

        setLoading(false);
      }
    }

    init();
  }, [formMode, routeParam?.claimRefId, routeParam?.claimId]);

  const validSchema =
    formSchemas?.ui &&
    formSchemas?.ajv[DYNAMIC_FORM_MODE.CREATE === mode ? 'create' : 'update'];

  return (
    <Layout AppModule={AppModule}>
      <ScrollingProvider top={100}>
        {claimData?.claimFormId && (
          <HeaderSection
            AppModule={AppModule}
            claimData={claimData}
            steps={getSteps(formatMessage, claimData?.claimFormId, AppModule)}
          />
        )}
        {loading && (
          <LoadingEvent mt={4}>
            <FormattedMessage id="PROCESSING" defaultMessage="Processing..." />
          </LoadingEvent>
        )}
        {!loading && pageError && (
          <Grid2 mt={4}>
            <SpecialSection color="error.main">
              <Typography color="error">{pageError.errorMessage}</Typography>
            </SpecialSection>
          </Grid2>
        )}
        {!loading && !pageError && (
          <Grid2>
            <ConfirmationPopUp reference={confirmationPopUpRef} />
            <RejectClaimModal
              reference={rejectClaimModalRef}
              claimId={claimId}
              AppModule={AppModule}
            />
            <UnderwriterApproveClaimModal
              reference={underwriteApproveClaimModalRef}
              claimId={claimId}
              AppModule={AppModule}
            />
            <UnderwriterRejectClaimModal
              reference={underwriteRejectClaimModalRef}
              claimId={claimId}
              AppModule={AppModule}
            />
            <ApproveClaimModal
              reference={approveClaimModalRef}
              claimId={claimId}
              getFormData={() => formRef?.current?.getValues()}
              claimData={claimData}
              AppModule={AppModule}
            />

            {errorBlock}

            {validSchema ? (
              <DynamicForm
                key={claimId}
                ignoreUnSaved={false}
                formId={claimId}
                uiSchema={formSchemas.ui}
                jsonSchema={
                  formSchemas.ajv[
                    DYNAMIC_FORM_MODE.CREATE === mode ? 'create' : 'update'
                  ]
                }
                onSubmitSuccess={async (data, additionalData) => {
                  setFormSubmitting(true);
                  setErrorBlock(null);

                  const formData = prepareFormData({
                    formData: data,
                    claimData,
                    currentFormMode: mode,
                    type: additionalData,
                    claimStatus: claimData?.claimStatus?.key,
                    AppModule,
                  });

                  let newClaimData = claimData;
                  let result = null;
                  const isSubmit =
                    additionalData === 'submit' &&
                    (mode === DYNAMIC_FORM_MODE.CREATE ||
                      mode === DYNAMIC_FORM_MODE.EDIT);

                  if (isSubmit) {
                    result = await submitClaim(formData, additionalData, {
                      updateOne,
                    });
                  } else if (DYNAMIC_FORM_MODE.EDIT === mode) {
                    result = await updateClaim(formData, additionalData, {
                      updateOne,
                      claimId,
                    });
                  } else if (DYNAMIC_FORM_MODE.CREATE === mode) {
                    result = await createClaim(formData, additionalData, {
                      createOne,
                    });
                  }
                  if (result) {
                    snackbar.showMessage({
                      type: result.success ? '' : 'error',
                      data: formatMessage(
                        result.message.message,
                        result.message.params,
                      ),
                    });

                    if (
                      result.data &&
                      result.data.claimNumber &&
                      claimId !== result.data.claimNumber
                    ) {
                      navigate(`/claim/edit/${result.data.claimNumber}`, {
                        replace: true,
                      });
                    } else if (result.data) {
                      if (
                        claimData.claimStatus.key !==
                        result.data.claimStatus.key
                      ) {
                        setMode(
                          getFormMode({
                            originalMode: mode,
                            claimStatus: result.data.claimStatus.key,
                            userAccess,
                            AppModule,
                          }),
                        );
                      }

                      const additionalClaimInfoData = await additionalClaimInfo(
                        result.data,
                        result.data.claimNumber,
                      );

                      newClaimData = {
                        ...result.data,
                        ...additionalClaimInfoData,
                      };
                      setClaimData(newClaimData);
                      formRef.current.update(newClaimData);
                    }
                    const newErrors = getErrorBlock({
                      claimStatus: newClaimData?.claimStatus?.key,
                      formErrors: result?.errors,
                      claimData: newClaimData,
                      AppModule,
                    });
                    setErrorBlock(newErrors);
                    formRef?.current?.setErrors(result.errors);
                  }
                  setFormSubmitting(false);
                }}
                onSubmitError={result => {
                  setErrorBlock(
                    getErrorBlock({
                      claimStatus: claimData?.claimStatus?.key,
                      formErrors: result,
                      claimData,
                      AppModule,
                    }),
                  );
                  formRef?.current?.setErrors(result);
                  setFormSubmitting(false);
                }}
                reference={formRef}
                additionalComponents={getAdditionalComponents(
                  claimData,
                  mode,
                  AppModule,
                )}
                initialFormValues={claimData}
                formMode={mode}
                masterData={masterData}
                initialized={!loading}
                loadingComponent={<LoadingEvent>Loading Schema</LoadingEvent>}
                errors={claimData?.claimErrors || []}
                effectFunctions={effectFunctions(mode)}
                unsavedChangeMessage={formatMessage({
                  id: 'UNSAVED_CHANGES_MESSAGE',
                  defaultMessage:
                    'There are some unsaved changes in the form. Do you want to discard all the changes?',
                })}
                formatMessage={formatMessage}
                memoParams={[claimData?.claimStatus?.key, mode]}
              />
            ) : (
              <SpecialSection style={{ marginTop: '100px' }} color="error.main">
                <Typography mt={3} mb={3} color="error.main">
                  <FormattedMessage
                    id="FORM_SCHEMA_NOT_FOUND"
                    defaultMessage="Error: No schema information found to show the form"
                  />
                </Typography>
              </SpecialSection>
            )}

            <StackedFloatingButtons
              position={{ bottom: true, right: true }}
              buttons={getPageButtons(mode)}
            />
          </Grid2>
        )}
      </ScrollingProvider>
    </Layout>
  );
}

export default observer(ClaimPage);
