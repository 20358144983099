import { useIntl } from 'react-intl';
import MaterialReactTable from 'material-react-table';
import { useStore } from '@servicexcelerator/ui-design-system';
import getColumns from './columns';

function ClaimServiceHistory({ data, defaultMessage, AppModule }) {
  const { formatMessage } = useIntl();
  const store = useStore();
  if (Array.isArray(data) && data.length > 0) {
    return (
      <MaterialReactTable
        localization={store.domain.locale.getForMuiTable()}
        columns={getColumns(formatMessage, AppModule)}
        data={data}
        getRowId={row => row.statusChangeDateTime}
        muiTablePaperProps={{ elevation: 0 }}
        enableColumnFilters={false}
        enableTopToolbar={false}
        enablePagination={false}
        enableColumnActions={false}
      />
    );
  }
  return defaultMessage;
}

export default ClaimServiceHistory;
