import { useState } from 'react';
import MenuItems from './MenuItems';
import MainMenuSidebar from './MainMenuSidebar';

/*
 * TODO: Still a buggy component written in hurry, need to fix logic and make code more readable
 */
function MainMenu({ menuItems, logoHref = null, logoTarget = '' }) {
  const [open, setOpen] = useState(true);
  return (
    <MainMenuSidebar open={open} setOpen={setOpen}>
      <MenuItems
        logoHref={logoHref}
        logoTarget={logoTarget}
        menuItems={menuItems}
        collapsed={!open}
        setCollapse={collapse => setOpen(!collapse)}
      />
    </MainMenuSidebar>
  );
}

export default MainMenu;
