import { nanoid } from 'nanoid';
import ClaimFeeds from '../../../ui/ClaimFeeds';
import NotesAttachment from '../../../ui/NotesAttachment';
import {
  PaymentSummaryCalculated,
  PaymentSummaryReceived,
} from '../../../ui/PaymentSummary';
import PartsTotalCalculated from '../../../ui/PartsSummary/PartsTotalCalculated';
import PartsTotalWithPartsManager from '../../../ui/PaymentSummary/PartsTotalWithPartsManager';
import RepeatRepairClaimIdControl from '../../../ui/RepeatRepairClaimIdControl';
import PaymentSimpleTableControl from '../../../ui/PaymentSimpleTableControl';
import ClaimServiceHistory from '../../../ui/ClaimServiceHistory';

const getAdditionalComponents = (claimData, formMode, AppModule) => {
  const { Components } = AppModule;
  const { Brands, Cities, States, LinkTextControl } = Components;

  const serviceAdministrator = claimData?.serviceAdministrator;
  const serviceAdministratorClientNumber =
    claimData?.serviceAdministratorClientNumber;
  const addressCountryCode =
    claimData?.customer?.customerAddress?.addressCountryCode;
  const addressState = claimData?.customer?.customerAddress?.addressState;
  const originalClaimNumber = claimData?.originalClaim?.claimNumber;
  return {
    Feeds: props => (
      <ClaimFeeds
        key={nanoid()}
        claimNumber={claimData?.claimNumber}
        AppModule={AppModule}
        {...props}
      />
    ),
    NotesAttachment: () => (
      <NotesAttachment
        key={nanoid()}
        AppModule={AppModule}
        formMode={formMode}
        claimNumber={claimData?.claimNumber}
        claimStatus={claimData?.claimStatus}
      />
    ),
    ServiceHistoryTable: () => (
      <ClaimServiceHistory
        key={nanoid()}
        AppModule={AppModule}
        claimNumber={claimData?.claimNumber}
        modelNumber={claimData?.modelNumber}
        serialNumber={claimData?.serialNumber}
        serviceAdministratorContractNumber={
          claimData?.claimAdditional?.serviceAdministratorServiceContractNumber
        }
      />
    ),
    BrandsSelectFormControl: props => (
      <Brands
        {...props}
        serviceAdministrator={serviceAdministrator}
        serviceAdministratorClientNumber={serviceAdministratorClientNumber}
      />
    ),
    StatesSelectFormControl: props => <States {...props} />,
    CitiesSelectFormControl: props => (
      <Cities
        defaultCountryCode={addressCountryCode}
        defaultStateCode={addressState}
        {...props}
      />
    ),
    CalculatedPaymentApprovedFormControl: props => (
      <PaymentSummaryCalculated
        claimData={claimData}
        paymentType="amountApproved"
        AppModule={AppModule}
        {...props}
      />
    ),
    CalculatedPaymentRequestedFormControl: props => (
      <PaymentSummaryCalculated
        claimData={claimData}
        paymentType="amountRequested"
        AppModule={AppModule}
        {...props}
      />
    ),
    ReceivedPaymentApprovedFormControl: props => (
      <PaymentSummaryReceived paymentType="amountApproved" {...props} />
    ),
    ReceivedPaymentRequestedFormControl: props => (
      <PaymentSummaryReceived paymentType="amountRequested" {...props} />
    ),
    PartsTotalWithPartsManager: props => (
      <PartsTotalWithPartsManager
        paymentType="amountRequested"
        AppModule={AppModule}
        {...props}
      />
    ),
    PartsTotalCalculated: props => (
      <PartsTotalCalculated
        formMode={formMode}
        AppModule={AppModule}
        {...props}
      />
    ),
    RepeatRepairClaimIdControl: props => (
      <RepeatRepairClaimIdControl {...props} />
    ),
    PaymentSimpleTableControl: props => (
      <PaymentSimpleTableControl AppModule={AppModule} {...props} />
    ),
    LinkTextControl: props => (
      <LinkTextControl {...props} to={`/claim/edit/${originalClaimNumber}`}>
        {originalClaimNumber}
      </LinkTextControl>
    ),
  };
};

export default getAdditionalComponents;
