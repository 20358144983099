const SESSION_STORAGE_KEYCLOAK_CONFIG = 'KEYCLOAK_CONFIG';

const ROUTE_LOADING_TYPE = {
  ROUTE_LAZY_LOADING: 'ROUTE_LAZY_LOADING',
  ROUTE_PERMISSION_CHECK: 'ROUTE_PERMISSION_CHECK',
};

const COMPANY_TYPE = {
  SERVICE_ADMINISTRATOR: 'SERVICE_ADMINISTRATOR',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
};

const PERMISSIONS = {
  ADJUDICATE_CLAIMS: 'ADJUDICATE_CLAIMS',
  MODIFY_CLAIMS: 'MODIFY_CLAIMS',
  UNDERWRITER_APPROVE: 'UNDERWRITER_APPROVE',
  UNDERWRITER_REJECT: 'UNDERWRITER_REJECT',
};

const WIDGET_TYPE = {
  CLAIMS: 'CLAIMS',
  SERVICEJOB: 'SERVICEJOB',
};

const CLAIM_STATUS = {
  INC: 'INC',
  CAN: 'CAN',
  SUB: 'SUB',
  PAR: 'PAR',
  PUR: 'PUR',
  PWR: 'PWR',
  COR: 'COR',
  APP: 'APP',
  REJ: 'REJ',
  PAD: 'PAD',
  TRX: 'TRX',
};

const FIELD_TYPES = {
  AMOUNT: 'AMOUNT',
  PHONE: 'PHONE',
  PHONE_TYPE: 'PHONE_TYPE',
  DATE: 'DATE',
};

export {
  COMPANY_TYPE,
  PERMISSIONS,
  CLAIM_STATUS,
  SESSION_STORAGE_KEYCLOAK_CONFIG,
  FIELD_TYPES,
  ROUTE_LOADING_TYPE,
  WIDGET_TYPE,
};
