import { LoggerUtil } from '@servicexcelerator/ui-design-system';

const hasClaimErrors = responseData =>
  responseData?.claimErrors &&
  Array.isArray(responseData?.claimErrors) &&
  responseData?.claimErrors.length > 0;

async function submitClaim(data, additionalData, { updateOne }) {
  const result = {
    message: {
      message: {
        id: 'ERROR_SAVING_CLAIM',
        defaultMessage:
          'The Claim Could Not be Submitted due to Errors on the Form',
      },
    },
    errors: null,
    success: false,
    data: null,
  };

  const claimSubmitResponse = await updateOne(
    `/claims/v1/claim/operation/submit`,
    data,
  );

  // Todo: Need to figure out when we get errors vs error
  if (claimSubmitResponse?.errors) {
    result.errors = claimSubmitResponse.errors;
  } else if (claimSubmitResponse?.error) {
    result.errors = claimSubmitResponse.error;
  } else if (
    claimSubmitResponse?.data &&
    hasClaimErrors(claimSubmitResponse?.data)
  ) {
    result.errors = claimSubmitResponse?.data?.claimErrors;
    result.data = claimSubmitResponse?.data;
  } else {
    result.data = claimSubmitResponse?.data;
    result.success = true;
    result.message = {
      message: {
        id: 'CLAIM_X_SUBMITTED_SUCCESSFULLY',
        defaultMessage: 'Claim {claimNumber} was submitted successfully',
      },
      params: {
        claimNumber: claimSubmitResponse?.data?.claimNumber,
      },
    };
  }

  LoggerUtil.log('Submit Response', {
    additionalData,
    request: data,
    response: claimSubmitResponse,
    actionResult: result,
  });

  return result;
}

export default submitClaim;
