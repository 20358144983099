import { DATE_FORMAT } from '@servicexcelerator/ui-design-system';
import dayjs from 'dayjs';

function toShortDate(date) {
  const dayjsDate = dayjs(date);
  return dayjsDate.format(DATE_FORMAT.SHORT_DATE);
}

function toMediumDate(date) {
  const dayjsDate = dayjs(date);
  return dayjsDate.format(DATE_FORMAT.MEDIUM_DATE);
}

function toLongDate(date) {
  const dayjsDate = dayjs(date);
  return dayjsDate.format(DATE_FORMAT.LONG_DATE);
}

function toDate(date, dateFormat = DATE_FORMAT.MEDIUM_DATE) {
  const dayjsDate = dayjs(date);
  return dayjsDate.format(dateFormat);
}

export { toShortDate, toLongDate, toMediumDate, toDate };
