import {
  Breadcrumbs,
  DateTimeUtil,
  LoggerUtil,
  PageSection,
  TableControls,
  useDataProvider,
  useSnackbar,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { Box, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ClaimSearch from '../../ui/ClaimSearch';
import getColumns from './lib/columns';

function getExistingParamsFromUrlOrStore(store) {
  const currentUrl = new URL(window.location.href);
  const page = currentUrl?.searchParams?.get('page') || null;
  const limit = currentUrl?.searchParams?.get('limit') || null;
  const filters = currentUrl?.searchParams?.get('filters') || null;
  const sort = currentUrl?.searchParams?.get('sorting') || null;
  if (page || limit || filters || sort) {
    currentUrl?.searchParams?.delete('page');
    currentUrl?.searchParams?.delete('limit');
    currentUrl?.searchParams?.delete('filters');
    currentUrl?.searchParams?.delete('sorting');
    return {
      page: page ? parseInt(page, 10) : 0,
      limit: limit ? parseInt(limit, 10) : 20,
      filters: filters ? JSON.parse(filters) : [],
      sort: sort ? JSON.parse(sort) : [],
    };
  }
  return store.uiState.claimSearchForm.get();
}

const adjustDateFilters = (filterName, filterValue) => {
  switch (filterName) {
    case 'claimStatusDateTimeFrom':
    case 'createdDateTimeFrom': {
      return DateTimeUtil.localToISOStartOfDay(filterValue);
    }
    case 'claimStatusDateTimeTo':
    case 'createdDateTimeTo': {
      return DateTimeUtil.localToISOEndOfDay(filterValue);
    }
    default:
      return filterValue;
  }
};

function columnFiltersArrayToObject(columnFilters) {
  const columnFiltersForService = columnFilters?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: adjustDateFilters(item.id, item.value),
    }),
    {},
  );
  return columnFiltersForService;
}

function SearchClaims({ AppModule }) {
  const { Components, Constants, Icons } = AppModule;
  const { BackButton } = Icons;
  const { useUserAccess, Layout, Header, DashboardHeader } = Components;
  const { COMPANY_TYPE } = Constants;

  const { formatMessage } = useIntl();
  const store = useStore();
  const { getMany, getApiUrl, getOne } = useDataProvider();

  const userAccess = useUserAccess();
  const snackbar = useSnackbar();

  const params = getExistingParamsFromUrlOrStore(store);

  const tableControlEnabled = false;

  // data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  // table state
  const [rowCount, setRowCount] = useState(params?.rowCount || 0);
  const [columnFilters, setColumnFilters] = useState(params?.filters || []);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState(params?.sorting || []);
  const [pagination, setPagination] = useState({
    pageIndex: params?.page || 0,
    pageSize: params?.limit || 20,
  });

  const [tableControl, setTableControl] = useState({
    resize: false,
    move: false,
    filter: false,
  });

  const columns = useMemo(
    () => getColumns(formatMessage, AppModule),
    [formatMessage],
  );

  async function fetchData() {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const convertedFilters = {
      ...columnFiltersArrayToObject(columnFilters),
    };

    const apiUrl = new URL(`${getApiUrl()}/claims/v1/claimdashboard/search`);

    apiUrl.searchParams.set('page', `${pagination.pageIndex + 1}`);
    apiUrl.searchParams.set('limit', `${pagination.pageSize}`);
    apiUrl.searchParams.set('filters', JSON.stringify(convertedFilters || {}));
    apiUrl.searchParams.set('sorting', JSON.stringify(sorting || []));

    try {
      const response = await getMany(apiUrl.href);
      if (response?.data) {
        const responseData = response?.data;
        setData(responseData?.claims);
        setRowCount(responseData?.pagination?.totalItems);
        store.uiState.claimSearchForm.set({
          page: pagination.pageIndex,
          limit: pagination.pageSize,
          filters: columnFilters,
          rowCount: responseData?.pagination?.totalItems,
          sorting,
        });
      }
    } catch (error) {
      setIsError(true);
      LoggerUtil.log('Claim Search', error);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  }

  const exportRecords = async () => {
    const url = new URL(
      `${getApiUrl()}/claims/v1/download/operation/presignedurl`,
    );

    const convertedFilters = {
      ...columnFiltersArrayToObject(columnFilters),
    };

    url.searchParams.set('filters', JSON.stringify(convertedFilters || {}));
    url.searchParams.set('sorting', JSON.stringify(sorting || []));

    const result = await getOne(url.href);
    const downloadUrl = result?.data?.downloadUrl || null;
    if (downloadUrl) {
      window.open(`${getApiUrl()}${downloadUrl}`, '', 'height=400,width=600');
    } else {
      snackbar.showMessage({
        type: 'error',
        data: formatMessage({
          id: 'ERROR_DOWNLOADING_FILE',
          defaultMessage: 'There was an error downloading the file',
        }),
      });
    }
  };

  const HeaderContent = tableControlEnabled ? (
    <TableControls
      localization={store.domain.locale.get()}
      onChange={(key, checked) => {
        setTableControl({
          ...tableControl,
          [key]: checked,
        });
      }}
      justifyContent="end"
    />
  ) : null;

  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const breadCrumb = [
    {
      label: formatMessage({
        id: 'DASHBOARD',
        defaultMessage: 'Dashboard',
      }),
      href: '/dashboard',
    },
  ];

  breadCrumb.push({
    label: formatMessage({
      id: 'CLAIMS',
      defaultMessage: 'Claims',
    }),
  });

  return (
    <Layout AppModule={AppModule}>
      <Header sx={{ mb: 2 }}>
        <DashboardHeader />
      </Header>

      <div>
        <Grid2 container pt={0} pb={0} alignItems="center">
          <Grid2 xs={6}>
            <Breadcrumbs links={breadCrumb} />
          </Grid2>
          <Grid2 textAlign="right" xs={6}>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                window.history.back();
              }}
              startIcon={<BackButton />}>
              <FormattedMessage id="BACK_BUTTON" defaultMessage="Back" />
            </Button>
          </Grid2>
        </Grid2>
      </div>
      <Grid2 mt={3}>
        <Grid2 mb={2}>
          <ClaimSearch
            AppModule={AppModule}
            onColumnFiltersChanged={newColumnFilters => {
              setColumnFilters(newColumnFilters);
              setGlobalFilter(null);
            }}
            columnFilters={columnFilters}
            formValues={columnFilters}
          />
        </Grid2>
        <Grid2>
          <PageSection
            HeaderContent={HeaderContent}
            headerIcon={ContentPasteOffIcon}
            label={formatMessage({
              id: 'CLAIMS',
              defaultMessage: 'Claims',
            })}>
            <MaterialReactTable
              localization={store.domain.locale.getForMuiTable()}
              columns={columns}
              data={data}
              getRowId={row => row.claimNumber}
              rowCount={rowCount}
              manualFiltering
              manualPagination
              manualSorting={false}
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              enableColumnFilters={false}
              enableSorting={false}
              enableFilters={false}
              initialState={{
                showColumnFilters: false,
                columnVisibility: {
                  serviceAdministratorName:
                    userAccess.companyType === COMPANY_TYPE.SERVICE_PROVIDER,
                  serviceProviderName:
                    userAccess.companyType ===
                    COMPANY_TYPE.SERVICE_ADMINISTRATOR,
                  serviceProviderNumber:
                    userAccess.companyType ===
                    COMPANY_TYPE.SERVICE_ADMINISTRATOR,
                },
              }}
              state={{
                columnFilters,
                globalFilter,
                isLoading,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
                sorting,
                pagination,
              }}
              muiToolbarAlertBannerProps={
                isError
                  ? {
                      color: 'error',
                      children: formatMessage({
                        id: 'ERROR_LOADING_DATA',
                        defaultMessage: 'Error loading data',
                      }),
                    }
                  : undefined
              }
              muiTablePaperProps={{ elevation: 0 }}
              renderTopToolbarCustomActions={() => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    mr: '0.5rem',
                    flex: 1,
                    justifyContent: 'end',
                    flexWrap: 'wrap',
                    alignContent: 'end',
                  }}>
                  <Button
                    color="primary"
                    onClick={() => exportRecords()}
                    startIcon={<DownloadIcon />}
                    variant="outlined">
                    <FormattedMessage
                      id="EXPORT_TO_CSV_BUTTON"
                      defaultMessage="Export"
                    />
                  </Button>
                </Box>
              )}
            />
          </PageSection>
        </Grid2>
      </Grid2>
    </Layout>
  );
}

export default SearchClaims;
