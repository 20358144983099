import { Navigate } from 'react-router-dom';
import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';
import ClaimFormPage from './pages/Claim';
import ClaimsSearch from './pages/Claims/search';

const routes = ({ ProtectedRoute, BubbleRouteError, AppModule }) => [
  {
    path: 'claim',
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard" />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'create/:claimRefId',
        element: (
          <ProtectedRoute
            componentProps={{
              formMode: DYNAMIC_FORM_MODE.CREATE,
              AppModule,
            }}
            Component={ClaimFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'view/:claimId',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.VIEW, AppModule }}
            Component={ClaimFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'edit/:claimId/:claimRefId?',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.EDIT, AppModule }}
            Component={ClaimFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
        errorElement: <BubbleRouteError />,
      },
    ],
  },
  {
    path: 'claims',
    children: [
      {
        path: '',
        element: <Navigate to="/claims/search" />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'search',
        element: (
          <ProtectedRoute
            componentProps={{ AppModule }}
            Component={ClaimsSearch}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: '*',
        element: <Navigate to="/claims/search" />,
        errorElement: <BubbleRouteError />,
      },
    ],
  },
];

export default routes;
