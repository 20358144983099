import { useTheme } from '@emotion/react';
import Icons from '@components/Icons';
import { CLAIM_STATUS } from '@constants/index';

function ClaimStatusIcon({ statusId, ...props }) {
  const theme = useTheme();

  const {
    ApprovedClaim,
    CancelledClaim,
    CorrectedClaim,
    IncompleteClaimStatus,
    PaidClaim,
    PendingAdministratorReviewClaim,
    PendingUnderWriterReceiptClaim,
    PendingUnderWriterReviewClaim,
    RejectedClaim,
    SubmittedClaim,
    TransferredClaim,
    Unknown,
  } = Icons;

  switch (statusId) {
    case CLAIM_STATUS.INC:
      return <IncompleteClaimStatus style={{ color: 'red' }} {...props} />;
    case CLAIM_STATUS.CAN:
      return <CancelledClaim style={{ color: 'red' }} {...props} />;
    case CLAIM_STATUS.SUB:
      return <SubmittedClaim style={{ color: 'green' }} {...props} />;
    case CLAIM_STATUS.PAR:
      return (
        <PendingAdministratorReviewClaim
          style={{ color: theme.palette.info.main }}
          {...props}
        />
      );
    case CLAIM_STATUS.PUR:
      return (
        <PendingUnderWriterReviewClaim
          style={{ color: theme.palette.warning.main }}
          {...props}
        />
      );
    case CLAIM_STATUS.PWR:
      return (
        <PendingUnderWriterReceiptClaim
          style={{ color: theme.palette.info.main }}
          {...props}
        />
      );
    case CLAIM_STATUS.COR:
      return <CorrectedClaim style={{ color: 'green' }} {...props} />;
    case CLAIM_STATUS.APP:
      return <ApprovedClaim style={{ color: 'green' }} {...props} />;
    case CLAIM_STATUS.REJ:
      return <RejectedClaim style={{ color: 'red' }} {...props} />;
    case CLAIM_STATUS.PAD:
      return <PaidClaim style={{ color: 'green' }} {...props} />;
    case CLAIM_STATUS.TRX:
      return <TransferredClaim style={{ color: 'orange' }} {...props} />;
    default:
      return <Unknown {...props} />;
  }
}

export default ClaimStatusIcon;
