import { useState } from 'react';
import { nanoid } from 'nanoid';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DYNAMIC_FORM_MODE,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';
import { Description, NoteAdd } from '@mui/icons-material';
import { Button, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import NoteModal from './NoteModal';
import ListNotes from './ListNotes';

const ButtonStyled = styled(Button)({
  textTransform: 'none',
});

function NotesToolbar({
  claimNumber,
  setSelectedNote = () => {},
  openNoteEditModal = () => undefined,
  AppModule,
}) {
  const { Components, Constants } = AppModule;
  const { useUserAccess, hasPermission } = Components;
  const { PERMISSIONS } = Constants;
  const userAccess = useUserAccess();
  const permissions = userAccess?.permissions || {};
  const canModifyClaim = hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions);
  if (!claimNumber || !canModifyClaim) {
    return null;
  }

  return (
    <Grid2 xs={12} textAlign="right">
      <ButtonStyled
        onClick={() => {
          setSelectedNote(null);
          openNoteEditModal(true);
        }}
        size="small"
        variant="contained"
        startIcon={<NoteAdd />}>
        <FormattedMessage id="ADD_NOTE" defaultMessage="Add Note" />
      </ButtonStyled>
    </Grid2>
  );
}

function NotesAttachment({ claimNumber, claimStatus, formMode, AppModule }) {
  const { Components, Constants } = AppModule;
  const { useUserAccess, hasPermission } = Components;
  const { COMPANY_TYPE, PERMISSIONS, CLAIM_STATUS } = Constants;

  const { formatMessage } = useIntl();
  const [selectedNote, setSelectedNote] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [listId, setListId] = useState(nanoid(4));
  const { deleteOne } = useDataProvider();
  const snackbar = useSnackbar();
  const userAccess = useUserAccess();
  const permissions = userAccess?.permissions || {};
  const canModifyClaim = hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions);

  let canModifyNote = false;
  if (
    canModifyClaim &&
    userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR
  ) {
    canModifyNote = true;
  } else if (
    canModifyClaim &&
    claimStatus &&
    claimStatus.key === CLAIM_STATUS.INC
  ) {
    canModifyNote = true;
  }

  const onNoteChanged = () => {
    setListId(nanoid(4));
  };

  const onDeleteNote = async note => {
    if (note?.id) {
      const result = await deleteOne(
        `/claims/v1/claim/${claimNumber}/note/${note.id}`,
      );
      if (result?.data) {
        snackbar.showMessage(
          formatMessage({
            id: 'NOTE_DELETED_SUCCESSFULLY',
            defaultMessage: 'Note was deleted successfully',
          }),
        );
        setListId(nanoid(4));
      } else {
        snackbar.showMessage({
          type: 'error',
          data: formatMessage({
            id: 'NOTE_COULD_NOT_BE_DELETED',
            defaultMessage: 'Note could not be deleted',
          }),
        });
      }
    }
  };

  return (
    <Grid2 container>
      <NotesToolbar
        formMode={formMode}
        claimNumber={claimNumber}
        setSelectedNote={setSelectedNote}
        openNoteEditModal={data => setOpenEditModal(data)}
        AppModule={AppModule}
      />
      {formMode === DYNAMIC_FORM_MODE.CREATE ? (
        <Grid2 xs>
          <Typography>
            <FormattedMessage
              id="SAVE_CLAIM_TO_ADD_NOTES"
              defaultMessage="Please save the claim to add notes"
            />
          </Typography>
        </Grid2>
      ) : (
        <Grid2 xs={12}>
          <ListNotes
            key={listId}
            onDeleteNote={noteToDelete => onDeleteNote(noteToDelete)}
            onNoteSelected={note => {
              setSelectedNote(note);
              setOpenEditModal(true);
            }}
            claimNumber={claimNumber}
            canModifyNote={canModifyNote}
            AppModule={AppModule}
          />
        </Grid2>
      )}
      {openEditModal && (
        <NoteModal
          NoteIcon={Description}
          note={selectedNote}
          claimNumber={claimNumber}
          setOpen={setOpenEditModal}
          onNoteChanged={onNoteChanged}
          AppModule={AppModule}
        />
      )}
    </Grid2>
  );
}

export default NotesAttachment;
