import { useAuth } from 'react-oidc-context';
import { LoggerUtil, useStore } from '@servicexcelerator/ui-design-system';

const useUserAccess = () => {
  const auth = useAuth();
  const store = useStore();
  const userID = auth.user.profile.sub;
  return store.domain.userAccess.get(userID);
};

const hasPermission = (permissionName, permissions) => {
  try {
    return permissions[permissionName];
  } catch {
    LoggerUtil.log('Permission Error', { permissions, permissionName });
    return false;
  }
};

export { useUserAccess, hasPermission };
