import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SpecialSection } from '@servicexcelerator/ui-design-system';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import getClaimReviews from './getClaimReviews';
import getFormResponseErrors from './getFormResponseErrors';

const getErrorBlock = ({ claimStatus, claimData, formErrors, AppModule }) => {
  if (claimStatus && claimStatus === 'REJ') {
    return (
      <Grid2 mt={2} ml={1} mr={1} mb={2}>
        <SpecialSection color="error.main">
          <Typography color="error.main">
            <FormattedMessage
              id="REJECT_REASON_X"
              defaultMessage="Reject Reason: {rejectReasonText}"
              values={{ rejectReasonText: claimData?.rejectReasonText }}
            />
          </Typography>
        </SpecialSection>
      </Grid2>
    );
  }

  const claimReviews = getClaimReviews(claimData?.claimReviews, AppModule);
  let claimAndFormErrors = null;

  if (formErrors) {
    claimAndFormErrors = getFormResponseErrors(formErrors, AppModule);
  }

  if (claimReviews || claimAndFormErrors) {
    return (
      <Grid2 mt={2} ml={1} mr={1} mb={2}>
        <SpecialSection color="error.main">
          <Typography>
            <FormattedMessage
              id="RESOLVE_ISSUES_BEFORE_FORM_SUBMIT"
              defaultMessage="Please resolve below issues before submitting the form."
            />
          </Typography>
          <ul>
            {claimAndFormErrors}
            {claimReviews}
          </ul>
        </SpecialSection>
      </Grid2>
    );
  }
  return null;
};

export default getErrorBlock;
