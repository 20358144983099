function LongDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toLongDate } = Utils;
  return original[fieldName] ? toLongDate(original[fieldName]) : '';
}

function UserDetails(props) {
  const {
    row: {
      original: { statusChangeByUser = null },
    },
  } = props;

  const { firstName, lastName } = statusChangeByUser || {
    firstName: '',
    lastName: '',
  };

  return `${firstName} ${lastName}`;
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'oldClaimStatus.value',
    header: formatMessage({
      id: 'OLD_CLAIM_STATUS',
      defaultMessage: 'Old Claim Status',
    }),
  },
  {
    accessorKey: 'newClaimStatus.value',
    header: formatMessage({
      id: 'NEW_CLAIM_STATUS',
      defaultMessage: 'New Claim Status',
    }),
  },
  {
    accessorKey: 'statusChangeByUser',
    header: formatMessage({
      id: 'USER',
      defaultMessage: 'User',
    }),
    Cell: props => UserDetails(props),
  },
  {
    accessorKey: 'statusChangeDateTime',
    header: formatMessage({
      id: 'CLAIM_STATUS_DATE_TIME',
      defaultMessage: 'Dated',
    }),
    Cell: props => LongDateDisplay(props, 'statusChangeDateTime', AppModule),
  },
];

export default getColumns;
