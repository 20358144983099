import { LoggerUtil } from '@servicexcelerator/ui-design-system';

const hasClaimErrors = responseData =>
  responseData?.claimErrors &&
  Array.isArray(responseData?.claimErrors) &&
  responseData?.claimErrors.length > 0;

async function updateClaim(data, additionalData, { updateOne, claimId }) {
  const result = {
    message: {
      message: {
        id: 'ERROR_SAVING_CLAIM',
        defaultMessage:
          'The Claim Could Not be Submitted due to Errors on the Form',
      },
    },
    errors: null,
    success: false,
    data: null,
  };

  const claimUpdateResponse = await updateOne(
    `/claims/v1/claim/${claimId}/operation/update`,
    data,
  );

  // Todo: Need to figure out when we get errors vs error
  if (claimUpdateResponse?.errors) {
    result.errors = claimUpdateResponse.errors;
  } else if (claimUpdateResponse?.error) {
    result.errors = claimUpdateResponse.error;
  } else {
    if (hasClaimErrors(claimUpdateResponse?.data)) {
      result.errors = claimUpdateResponse?.data?.claimErrors;
    }
    result.data = claimUpdateResponse?.data;
    result.success = true;
    result.message = {
      message: {
        id: 'CLAIM_X_UPDATED_SUCCESSFULLY',
        defaultMessage: 'Claim {claimNumber} was saved',
      },
      params: {
        claimNumber: claimUpdateResponse?.data?.claimNumber,
      },
    };
  }

  LoggerUtil.log('Update Response', {
    additionalData,
    request: data,
    response: claimUpdateResponse,
    actionResult: result,
  });

  return result;
}

export default updateClaim;
