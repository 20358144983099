import Box from '@mui/material/Box';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useEffect, useImperativeHandle, useState } from 'react';
import { DialogTitle, Typography } from '@mui/material';
import {
  LoadingEvent,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

function ApproveClaimModal({
  reference,
  claimId,
  claimData,
  getFormData = () => undefined,
  AppModule,
}) {
  const { Utils } = AppModule;
  const { ErrorMessage } = Utils;
  const { formatMessage } = useIntl();
  let limitOfLiabilityRemaining = null;
  if (
    claimData?.entitlementDetails &&
    Array.isArray(claimData?.entitlementDetails) &&
    claimData?.entitlementDetails.length > 0
  ) {
    if (claimData?.entitlementDetails[0]?.limitOfLiabilityRemaining) {
      limitOfLiabilityRemaining = parseFloat(
        claimData?.entitlementDetails[0]?.limitOfLiabilityRemaining,
      );
    }
  }

  const snackbar = useSnackbar();
  const { updateOne } = useDataProvider();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalApprovedAmount, setTotalApprovedAmount] = useState(0);

  const showError = err => {
    snackbar.showMessage({
      type: 'error',
      data:
        err?.message ||
        formatMessage({
          id: 'ERROR_COULD_NOT_APPROVE_CLAIM',
          defaultMessage: 'Error: Could not approve the claim',
        }),
    });
  };

  const onChange = async value => {
    if (!value) {
      setOpen(false);
    } else {
      try {
        const formData = getFormData();

        // Remove "amountApproved" property from each object in the data
        const claimPayment = {};
        Object.keys(formData.claimPayment).every(key => {
          const item = {};
          claimPayment[key] = Object.entries(formData.claimPayment[key]).reduce(
            (acc, payment) => {
              if (payment[0] !== 'amountRequested') {
                // eslint-disable-next-line prefer-destructuring
                item[payment[0]] = payment[1];
              }
              return {
                ...acc,
                ...item,
              };
            },
            {},
          );
          return true;
        });

        const result = await updateOne(
          `/claims/v1/claim/${claimId}/operation/approve`,
          {
            claimPayment,
          },
        );
        if (result?.data) {
          snackbar.showMessage(
            formatMessage({
              id: 'CLAIM_APPROVED_SUCCESSFULLY',
              defaultMessage: 'Claim was approved successfully',
            }),
          );
          window.location.reload();
        } else {
          showError(ErrorMessage(result));
        }
      } catch (err) {
        showError(err);
      }
    }
  };

  const getMessage = (remaining, total) => {
    let message = formatMessage(
      {
        id: 'DO_YOU_WANT_TO_APPROVE_CLAIM_FOR_X_AMOUNT',
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: 'Do you want to Approve this Claim for ${amount}?',
      },
      {
        amount: parseFloat(total).toFixed(2),
      },
    );

    if ((remaining || remaining === 0) && total > remaining) {
      message = formatMessage(
        {
          id: 'DO_YOU_WANT_TO_APPROVE_CLAIM_AMOUNT_X_OVER_REMAINING_LIMIT_OF_LIABILITY_Y',
          defaultMessage:
            // eslint-disable-next-line no-template-curly-in-string
            'Do you want to Approve this Claim for ${amount}, which is over the remaining limit of liability of ${remainingLiability}?',
        },
        {
          amount: parseFloat(total).toFixed(2),
          remainingLiability: parseFloat(remaining).toFixed(2),
        },
      );
    }
    return message;
  };

  useImperativeHandle(reference, () => ({
    setOpen(value) {
      setOpen(value);
    },
  }));

  useEffect(() => {
    if (!open) {
      setLoading(false);
    } else {
      setTotalApprovedAmount(0);
      const formData = getFormData();
      const calculatedTotal = Object.entries(formData.claimPayment).reduce(
        (acc, [, amountType]) => acc + (amountType?.amountApproved || 0),
        0,
      );
      setTotalApprovedAmount(parseFloat(calculatedTotal));
    }
  }, [open]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 400,
        bgcolor: 'background.paper',
      }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        open={open}>
        {!loading && (
          <DialogTitle style={{ padding: '8px 20px 30px', fontSize: '18px' }}>
            <FormattedMessage
              id="APPROVE_CLAIM"
              defaultMessage="Approve Claim"
            />
          </DialogTitle>
        )}
        {loading ? (
          <DialogContent>
            <LoadingEvent>
              <FormattedMessage
                id="APPROVING_CLAIM"
                defaultMessage="Approving Claim..."
              />
            </LoadingEvent>
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid2 container spacing={2} flexDirection="column">
              <Grid2>
                <Typography mb={1}>
                  {getMessage(limitOfLiabilityRemaining, totalApprovedAmount)}
                </Typography>
              </Grid2>
            </Grid2>
          </DialogContent>
        )}
        {!loading ? (
          <DialogActions
            style={{
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <Button variant="contained" onClick={() => onChange(true)}>
              <FormattedMessage id="APPROVE" defaultMessage="Approve" />
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '20px' }}
              onClick={() => onChange(false)}>
              <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </Box>
  );
}

export default ApproveClaimModal;
