import { LoggerUtil } from '@servicexcelerator/ui-design-system';

async function createClaim(data, additionalData, { createOne }) {
  const result = {
    message: {
      message: {
        id: 'ERROR_CREATING_CLAIM',
        defaultMessage: 'There was an error creating the claim',
      },
    },
    errors: null,
    success: false,
    data: null,
  };

  const createClaimResponse = await createOne('/claims/v1/claim', data);

  if (createClaimResponse?.errors) {
    result.errors = createClaimResponse.errors;
  } else if (
    !createClaimResponse?.errors &&
    createClaimResponse?.data?.claimNumber
  ) {
    result.data = createClaimResponse?.data;
    result.success = true;
    result.message = {
      message: {
        id: 'NEW_CLAIM_CREATED_WITH_ID_X',
        defaultMessage: 'New claim was created with claim id {claimNumber}',
      },
      params: {
        claimNumber: createClaimResponse?.data?.claimNumber,
      },
    };
  }

  LoggerUtil.log('Create Response', {
    additionalData,
    request: data,
    response: createClaimResponse,
    actionResult: result,
  });

  return result;
}

export default createClaim;
