import {
  ContentPaste,
  Home as HomeMui,
  NoteAltOutlined,
  DevicesOther,
  HandshakeOutlined,
  InfoOutlined,
  Payment as PaymentMui,
  PersonOutline,
  Send,
  SettingsOutlined,
  TextSnippetOutlined,
  ContentPasteSearchRounded,
  ErrorOutline,
  AssignmentLateOutlined,
  ContentPasteSearch,
  ContentPasteOff,
  Edit,
  Clear,
  Save,
  HelpOutline,
  Cancel,
  MarkEmailRead,
  AccountBox,
  ContactEmergency,
  CheckCircleOutline,
  PriceCheck,
  MoveUp,
  ArrowBackIos,
  ContentCopy,
  OpenInBrowser,
  RestartAlt,
  ResetTv,
  ManageSearch,
  Receipt,
  PlaylistAddCheck,
} from '@mui/icons-material';

function TransferredClaim(args) {
  return <MoveUp {...args} />;
}

function PaidClaim(args) {
  return <PriceCheck {...args} />;
}

function ApprovedClaim(args) {
  return <CheckCircleOutline {...args} />;
}

function PendingUnderWriterReviewClaim(args) {
  return <ContactEmergency {...args} />;
}

function PendingUnderWriterReceiptClaim(args) {
  return <Receipt {...args} />;
}

function PendingAdministratorReviewClaim(args) {
  return <AccountBox {...args} />;
}

function SubmittedClaim(args) {
  return <MarkEmailRead {...args} />;
}

function CorrectedClaim(args) {
  return <PlaylistAddCheck {...args} />;
}

function ViewClaim(args) {
  return <ContentPasteSearchRounded {...args} />;
}

function IncompleteClaim(args) {
  return <AssignmentLateOutlined {...args} />;
}

function InReviewClaim(args) {
  return <ContentPasteSearch {...args} />;
}

function RejectedClaim(args) {
  return <ContentPasteOff {...args} />;
}

function IncompleteClaimStatus(args) {
  return <ErrorOutline {...args} />;
}

function CancelledClaim(args) {
  return <Cancel {...args} />;
}

function Home(args) {
  return <HomeMui {...args} />;
}

function Unknown(args) {
  return <HelpOutline {...args} />;
}

function Claim(args) {
  return <ContentPaste {...args} />;
}

function Claims(args) {
  return <ContentPaste {...args} />;
}

function ClaimSearch(args) {
  return <ManageSearch {...args} />;
}

function NewClaim(args) {
  return <NoteAltOutlined {...args} />;
}
function BasicInfo(args) {
  return <InfoOutlined {...args} />;
}
function Customer(args) {
  return <PersonOutline {...args} />;
}
function Product(args) {
  return <DevicesOther {...args} />;
}
function Service(args) {
  return <HandshakeOutlined {...args} />;
}
function Parts(args) {
  return <SettingsOutlined {...args} />;
}
function Payment(args) {
  return <PaymentMui {...args} />;
}

function NotesAttachment(args) {
  return <TextSnippetOutlined {...args} />;
}

function ReOpenClaimButton(args) {
  return <OpenInBrowser {...args} />;
}

function DuplicateClaimButton(args) {
  return <ContentCopy {...args} />;
}

function SubmitButton(args) {
  return <Send {...args} />;
}

function EditButton(args) {
  return <Edit {...args} />;
}

function CancelButton(args) {
  return <Clear {...args} />;
}

function SaveButton(args) {
  return <Save {...args} />;
}

function ResetButton(args) {
  return <RestartAlt {...args} />;
}

function BackButton(args) {
  return <ArrowBackIos {...args} />;
}

function CloseButton(args) {
  return <ResetTv {...args} />;
}

export default {
  CorrectedClaim,
  PendingUnderWriterReceiptClaim,
  CloseButton,
  ResetButton,
  ReOpenClaimButton,
  DuplicateClaimButton,
  BackButton,
  TransferredClaim,
  PaidClaim,
  ApprovedClaim,
  PendingUnderWriterReviewClaim,
  PendingAdministratorReviewClaim,
  SubmittedClaim,
  CancelledClaim,
  Home,
  Claim,
  Claims,
  NewClaim,
  ClaimSearch,
  BasicInfo,
  Customer,
  Product,
  Service,
  Parts,
  Payment,
  NotesAttachment,
  SubmitButton,
  ViewClaim,
  IncompleteClaim,
  IncompleteClaimStatus,
  InReviewClaim,
  RejectedClaim,
  EditButton,
  CancelButton,
  SaveButton,
  Unknown,
};
