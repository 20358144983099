import { Avatar, Box, IconButton, Typography, useTheme } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useIntl } from 'react-intl';

function NoteAvatar(props) {
  const {
    row: {
      original: { lastModifiedBy, createdBy } = {
        lastModifiedBy: {},
        createdBy: {},
      },
    },
  } = props;
  const theme = useTheme();
  let { firstName = null, lastName = null } = createdBy;
  if (lastModifiedBy?.firstName && lastModifiedBy?.lastName) {
    firstName = lastModifiedBy?.firstName;
    lastName = lastModifiedBy?.lastName;
  }

  let nameInitials = null;

  if (firstName && lastName) {
    nameInitials = `${firstName[0]}${lastName[0]}`;
  }

  return (
    <Avatar
      sx={{
        bgcolor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
      }}
      alt={`${firstName} ${lastName}`}>
      {nameInitials}
    </Avatar>
  );
}

function Note(props) {
  const {
    row: {
      original: { note = '' } = {
        note: '',
      },
    },
  } = props;
  return <Typography>{note}</Typography>;
}

function CreatedDate(props, AppModule) {
  const {
    row: {
      original: { createdDateTime = null } = {
        createdDateTime: null,
      },
    },
  } = props;
  const { Utils } = AppModule;
  const { toLongDate } = Utils;
  return <Typography>{toLongDate(createdDateTime)}</Typography>;
}

function NoteButtons(
  props,
  onNoteSelected,
  onDeleteNote,
  canModifyNote,
  confirmationPopUpRef,
) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  if (!canModifyNote) {
    return null;
  }

  return (
    <Box textAlign="right" mr={2}>
      <IconButton
        style={{ background: theme.palette.secondary.light }}
        onClick={() => {
          onNoteSelected(props?.row?.original || null);
        }}>
        <Edit style={{ color: theme.palette.primary.main }} />
      </IconButton>
      <IconButton
        style={{
          background: theme.palette.secondary.light,
          marginLeft: '20px',
        }}
        onClick={() => {
          confirmationPopUpRef.current.init({
            message: formatMessage({
              id: 'DO_YOU_WANT_TO_DELETE_NOTE',
              defaultMessage: 'Do you want to delete this note?',
            }),
            positiveButtonText: formatMessage({
              id: 'YES',
              defaultMessage: 'Yes',
            }),
            negativeButtonText: formatMessage({
              id: 'CANCEL',
              defaultMessage: 'Cancel',
            }),
            loadingMessage: formatMessage({
              id: 'DELETING_NOTE',
              defaultMessage: 'Deleting the note...',
            }),
            title: formatMessage({
              id: 'DELETE_NOTE',
              defaultMessage: 'Delete Note',
            }),
            onChange: async value => {
              if (value) {
                confirmationPopUpRef.current.setLoading(true);
                await onDeleteNote(props?.row?.original || null);
              }
              confirmationPopUpRef.current.setOpen(false);
            },
          });
          confirmationPopUpRef.current.setOpen(true);
        }}>
        <Delete style={{ color: theme.palette.error.main }} />
      </IconButton>
    </Box>
  );
}

const columns = (
  onNoteSelected,
  onDeleteNote,
  canModifyNote,
  confirmationPopUpRef,
  AppModule,
) => [
  {
    accessorKey: 'id',
    Cell: props => NoteAvatar(props),
    size: 20,
    maxSize: 20,
  },
  {
    accessorKey: 'note',
    Cell: props => Note(props),
    minSize: 300,
  },
  {
    accessorKey: 'attachments',
    Cell: props =>
      Array.isArray(props.row.original?.attachments) &&
      props.row?.original?.attachments?.length > 0
        ? `${props.row?.original?.attachments.length} attachments`
        : '',
  },
  {
    accessorKey: 'createdDateTime',
    Cell: props => CreatedDate(props, AppModule),
    minSize: 90,
  },
  {
    accessorKey: 'NoteButtons',
    Cell: props =>
      NoteButtons(
        props,
        onNoteSelected,
        onDeleteNote,
        canModifyNote,
        confirmationPopUpRef,
      ),
  },
];

export default columns;
