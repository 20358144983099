import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  Required,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { merge } from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

function WithJob({ AppModule }) {
  const { Constants } = AppModule;
  const { CLAIM_STATUS } = Constants;
  const store = useStore();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getOne } = useDataProvider();

  const [jobNumber, setJobNumber] = useState('');
  const [jobNumberError, setJobNumberError] = useState(false);
  const [serviceErrors, setServiceErrors] = useState(null);

  const getInfoForServiceJob = async serviceJobNumber => {
    const data = await getOne(
      `/claims/v1/servicejob/claimfromservicejob/${serviceJobNumber}`,
    );
    return data;
  };

  const createEmptyClaim = async claimData => {
    const claimRef = nanoid();

    const claimFormIdRequest = {
      serviceadministrator: claimData?.serviceAdministrator,
      serviceadministratorclientnumber:
        claimData?.serviceAdministratorClientNumber,
      industrytype: claimData?.industryType,
      warrantytype: claimData?.warrantyType,
      serviceprovidernumber: claimData?.serviceProvider?.serviceProviderNumber,
      servicetype: claimData?.serviceType,
    };

    const claimFormIdResponse = await getOne(
      `/claims/v1/claim/operation/new`,
      claimFormIdRequest,
    );

    if (
      claimFormIdResponse?.errors &&
      Array.isArray(claimFormIdResponse?.errors) &&
      claimFormIdResponse.errors.length > 0
    ) {
      setServiceErrors(claimFormIdResponse?.errors);
    }
    if (!claimFormIdResponse?.errors) {
      const newClaimData = merge(
        {
          claimStatus: {
            key: CLAIM_STATUS.INC,
          },
        },
        claimData,
        claimFormIdResponse.data,
      );

      store.domain.claims.setProspectiveClaim(claimRef, newClaimData);
      navigate(`/claim/create/${claimRef}`, { replace: true });
      store.uiState.createClaimModal.setOpen(false);
    }
  };

  const handleJobNumberSubmit = async () => {
    setServiceErrors(null);
    const trimmedJobNumber = jobNumber?.trim();
    if (trimmedJobNumber) {
      const claimInfo = await getInfoForServiceJob(trimmedJobNumber);
      if (
        claimInfo?.errors &&
        Array.isArray(claimInfo?.errors) &&
        claimInfo.errors.length > 0
      ) {
        setServiceErrors(claimInfo?.errors);
      } else {
        createEmptyClaim(claimInfo?.data);
      }
    } else {
      setJobNumberError(true);
    }
  };

  const renderErrors = () => {
    if (Array.isArray(serviceErrors)) {
      return (
        <ul>
          {serviceErrors?.map(item => (
            <li key={JSON.stringify(item)} style={{ marginBottom: '5px' }}>
              <Typography color="error">
                {typeof item === 'string'
                  ? item
                  : item.defaultErrorMessage || item.errorMessage}
              </Typography>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  useEffect(() => {
    let jobNumberParam = searchParams.get('jobNumber');
    if (!jobNumberParam) {
      jobNumberParam = searchParams.get('jobnumber');
    }
    if (jobNumberParam) {
      setJobNumber(jobNumberParam);
    }
  }, [searchParams]);

  const buttons = [
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'CREATE_CLAIM_BUTTON',
        defaultMessage: 'Create Claim',
      }),
      type: 'button',
      onClick: () => handleJobNumberSubmit(),
    },
    {
      color: 'secondary',
      variant: 'contained',
      children: formatMessage({
        id: 'CANCEL_BUTTON',
        defaultMessage: 'Cancel',
      }),
      type: 'button',
      onClick: () => store.uiState.createClaimModal.setOpen(false),
    },
  ];

  return (
    <Grid2>
      <Box>{renderErrors()}</Box>
      <Box>
        <Typography mb={1}>
          <FormattedMessage id="JOB_NUMBER" defaultMessage="Job Number" />
          <Required show />
        </Typography>
        <TextField
          fullWidth
          size="small"
          value={jobNumber}
          onChange={e => {
            setJobNumber(e.target.value);
            if (e.target.value) {
              setJobNumberError(false);
              setServiceErrors(null);
            }
          }}
        />
        {jobNumberError && (
          <FormHelperText style={{ fontSize: '14px' }} error>
            <FormattedMessage id="REQUIRED" defaultMessage="Required" />
          </FormHelperText>
        )}
      </Box>
      <Grid2 container spacing={2} mt={6} justifyContent="center">
        <Grid2 textAlign="right" xs={6}>
          <Button {...buttons[0]} />
        </Grid2>
        <Grid2 textAlign="left" xs={6}>
          <Button {...buttons[1]} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default WithJob;
