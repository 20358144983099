import { Box, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  DYNAMIC_FORM_MODE,
  DynamicForm,
  LoadingEvent,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import jsonSchema from './jsonSchema.json';
import uiSchema from './uiSchema.json';

function WithoutJobForm({ AppModule }) {
  const { Constants } = AppModule;
  const { CLAIM_STATUS } = Constants;
  const { getOne } = useDataProvider();
  const formRef = useRef();
  const store = useStore();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const masterData = store.domain.masterData.get();

  const buttons = [
    {
      color: 'primary',
      variant: 'contained',
      children: formatMessage({
        id: 'CREATE_CLAIM_BUTTON',
        defaultMessage: 'Create Claim',
      }),
      type: 'button',
      onClick: () => formRef.current.submitForm(),
    },
    {
      color: 'secondary',
      variant: 'contained',
      children: formatMessage({
        id: 'CANCEL_BUTTON',
        defaultMessage: 'Cancel',
      }),
      type: 'button',
      onClick: () => store.uiState.createClaimModal.setOpen(false),
    },
  ];

  const onSubmitSuccess = async data => {
    const claimRef = nanoid();

    const preClaimData = {
      serviceadministrator: data.serviceAdministratorId,
      serviceadministratorclientnumber: data.serviceAdministratorClientNumber,
      industrytype: data.industryId,
      warrantytype: data.warrantyTypeId,
      serviceprovidernumber: data.serviceProviderId,
      servicetype: data.serviceTypeId,
    };

    const request = await getOne(
      `/claims/v1/claim/operation/new`,
      preClaimData,
    );

    store.domain.claims.setProspectiveClaim(claimRef, {
      ...request.data,
      claimStatus: {
        key: CLAIM_STATUS.INC,
      },
    });
    navigate(`/claim/create/${claimRef}`, { replace: true });
    store.uiState.createClaimModal.setOpen(false);
  };

  const onSubmitError = () => {
    // navigate('/claim-create');
  };

  return (
    <Box>
      <DynamicForm
        formId="claimCreateModal"
        uiSchema={uiSchema}
        jsonSchema={jsonSchema}
        onSubmitSuccess={onSubmitSuccess}
        onSubmitError={onSubmitError}
        reference={formRef}
        masterData={masterData}
        initialized
        loadingComponent={
          <LoadingEvent>
            <FormattedMessage
              id="LOADING_SCHEMA"
              defaultMessage="Loading Schema"
            />
          </LoadingEvent>
        }
        formMode={DYNAMIC_FORM_MODE.EDIT}
        formatMessage={formatMessage}
      />
      <Grid2 container spacing={2} mt={6} justifyContent="center">
        <Grid2 textAlign="right" xs={6}>
          <Button {...buttons[0]} />
        </Grid2>
        <Grid2 textAlign="left" xs={6}>
          <Button {...buttons[1]} />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default observer(WithoutJobForm);
