import { FormControl, InputLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FormValue, Required } from '@servicexcelerator/ui-design-system';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

function LinkTextControl(props) {
  const { localeId, label, to, children, required = false } = props;

  return (
    <Grid2 xs p={2}>
      <FormControl fullWidth>
        <InputLabel>
          <FormattedMessage id={localeId} defaultMessage={label} />
          <Required show={required} />
        </InputLabel>

        <FormValue>
          <Link to={to}>{children}</Link>
        </FormValue>
      </FormControl>
    </Grid2>
  );
}

export default LinkTextControl;
