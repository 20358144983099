function LongDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toLongDate } = Utils;

  return original[fieldName] ? toLongDate(original[fieldName]) : '';
}

function RuleResultTextDisplay(props, formatMessage, fieldName) {
  const {
    row: { original },
  } = props;
  return original[fieldName] === 0
    ? formatMessage({
        id: 'PASSED',
        defaultMessage: 'Passed',
      })
    : formatMessage({
        id: 'FAILED',
        defaultMessage: 'Failed',
      });
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'ruleName',
    header: formatMessage({
      id: 'RULE_NAME',
      defaultMessage: 'Rule Name',
    }),
  },
  {
    accessorKey: 'ruleResult',
    header: formatMessage({
      id: 'RULE_RESULT',
      defaultMessage: 'Rule Result',
    }),
    Cell: props => RuleResultTextDisplay(props, formatMessage, 'ruleResult'),
  },
  {
    accessorKey: 'createdDateTime',
    header: formatMessage({
      id: 'RULE_RESULT_CREATED_DATE_TIME',
      defaultMessage: 'Dated',
    }),
    Cell: props => LongDateDisplay(props, 'createdDateTime', AppModule),
  },
];

export default getColumns;
