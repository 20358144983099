import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { FormValue, useStore } from '@servicexcelerator/ui-design-system';
import { Controller } from 'react-hook-form';

function PaymentSummaryReceived(props) {
  const { control, name, paymentType, formId, decimal = 0 } = props;
  const [hasError, setHasError] = useState(false);
  const store = useStore();

  useEffect(() => {
    if (paymentType === 'amountRequested') {
      const currentValues = store.domain.formState.getState(formId);
      if (currentValues?.claimErrors) {
        const error = currentValues?.claimErrors.some(
          item => item.errorCode === 'TOTAL_REQUESTED_MISMATCH',
        );
        setHasError(error);
      } else {
        setHasError(false);
      }
    }
  }, [store.domain.formState.formState[formId]?.claimErrors]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field = {} }) => {
        const { value } = field;

        if (paymentType === 'amountApproved') {
          return <FormValue>&ndash;</FormValue>;
        }

        return (
          <FormValue {...(hasError && { color: 'error.main' })}>
            {value ? `$${parseFloat(value).toFixed(decimal)}` : ''}
          </FormValue>
        );
      }}
    />
  );
}

export default observer(PaymentSummaryReceived);
