import FOODLOSS2023 from '../../../formSchema/FOODLOSS2023/index';
import BASICOEM2023 from '../../../formSchema/BASICOEM2023/index';
import BASICHVAC2023 from '../../../formSchema/BASICHVAC2023/index';
import BASICCONTRACT2023 from '../../../formSchema/BASICCONTRACT2023/index';
import ELECTROLUX2023 from '../../../formSchema/ELECTROLUX2023/index';

function getFormSchemas(claimFormId) {
  switch (claimFormId) {
    case 'ELECTROLUX-2023':
      return ELECTROLUX2023;
    case 'FOODLOSS-2023':
      return FOODLOSS2023;
    case 'BASICOEM-2023':
      return BASICOEM2023;
    case 'BASICHVAC-2023':
      return BASICHVAC2023;
    case 'BASICCONTRACT-2023':
      return BASICCONTRACT2023;
    default:
      return null;
  }
}

export default getFormSchemas;
