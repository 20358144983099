import { Fab, CircularProgress, Stack } from '@mui/material';
import { nanoid } from 'nanoid';

/*
 * position  = { left, top, bottom, right } all boolean OR null
 */

const style = buttonPosition =>
  buttonPosition
    ? {
        position: 'fixed',
        zIndex: 2,
        ...(buttonPosition.left && { left: '50px' }),
        ...(buttonPosition.top && { top: '50px' }),
        ...(buttonPosition.right && { right: '50px' }),
        ...(buttonPosition.bottom && { bottom: '50px' }),
      }
    : {};

function FloatingButton({
  async = false,
  onClick = () => undefined,
  inProcess = false,
  Icon = null,
  processingLabel = 'processing...',
  position = null,
  children,
  addMarginBottomToBody = true,
  ...rest
}) {
  const onButtonClick = e => {
    onClick(e);
  };

  const IconNode =
    // eslint-disable-next-line no-nested-ternary
    inProcess && async ? (
      <CircularProgress
        sx={{ width: '20px !important', height: '20px !important', mr: 1 }}
        color="primary"
      />
    ) : Icon ? (
      <Icon sx={{ mr: 0.6, fontSize: '18px' }} />
    ) : null;

  const label = inProcess && async ? processingLabel : children;

  if (addMarginBottomToBody) {
    // This is needed so that when you scroll to page bottom, the floating button appear as if they are the last elements on page
    document.body.style['margin-bottom'] = '120px';
  }

  return (
    <Fab
      style={style(position)}
      variant="extended"
      color={inProcess ? 'secondary' : 'primary'}
      size="medium"
      onClick={onButtonClick}
      disabled={inProcess}
      {...rest}>
      {IconNode}
      {label}
    </Fab>
  );
}

export function StackedFloatingButtons({ buttons, position = null }) {
  return (
    <Stack style={style(position)} direction="row" spacing={2}>
      {buttons.map(buttonProp => (
        <FloatingButton key={nanoid()} {...buttonProp} />
      ))}
    </Stack>
  );
}

export default FloatingButton;
