import { Box, Tab, Tabs, Typography } from '@mui/material';

import { Modal, useStore } from '@servicexcelerator/ui-design-system';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Work, WorkOff } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import WithJob from './WithJob';
import WithoutJob from './WithoutJob';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

function CreateClaimModal({ AppModule }) {
  const { Icons } = AppModule;

  const { NewClaim } = Icons;
  const store = useStore();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const masterData = store.domain.masterData.get();

  let canCreateClaimWithoutJob = false;

  if (
    Object.prototype.hasOwnProperty.call(
      masterData,
      'SERVICE_ADMINISTRATOR_CLIENT',
    ) &&
    masterData.SERVICE_ADMINISTRATOR_CLIENT?.length > 0
  ) {
    canCreateClaimWithoutJob = true;
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const claimcreatesrc = searchParams.get('createmodal');
    if (claimcreatesrc === 'true') {
      store.uiState.createClaimModal.setOpen(true);
    }
  }, [searchParams]);

  return (
    <Modal
      PaperProps={{ sx: { width: '650px' } }}
      handleClose={() => store.uiState.createClaimModal.setOpen(false)}
      maxWidth="lg"
      title={formatMessage({
        id: 'CREATE_NEW_CLAIM',
        defaultMessage: 'Create new claim',
      })}
      open={store.uiState.createClaimModal.open}
      Icon={NewClaim}>
      {!canCreateClaimWithoutJob ? (
        <WithJob AppModule={AppModule} />
      ) : (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              <Tab
                iconPosition="start"
                icon={<Work />}
                label={formatMessage({
                  id: 'FROM_JOB',
                  defaultMessage: 'From Job',
                })}
                {...a11yProps(0)}
                value={0}
              />
              <Tab
                iconPosition="start"
                icon={<WorkOff />}
                label={formatMessage({
                  id: 'WITHOUT_JOB',
                  defaultMessage: 'Without Job',
                })}
                {...a11yProps(1)}
                value={1}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <WithJob AppModule={AppModule} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <WithoutJob AppModule={AppModule} />
          </CustomTabPanel>
        </Box>
      )}
    </Modal>
  );
}

export default observer(CreateClaimModal);
