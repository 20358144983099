import { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LoadingEvent,
  Modal,
  Required,
  SpecialSection,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import { AttachFile } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import FileView from './FileView';

function NoteModal({
  setOpen = () => undefined,
  NoteIcon = null,
  claimNumber,
  note: initialNote = null,
  onNoteChanged = () => undefined,
  AppModule,
}) {
  const { Constants } = AppModule;
  const { MAX_NOTE_LENGTH, NOTE_ACTION } = Constants;

  const { updateOne, createOne } = useDataProvider();
  const { formatMessage } = useIntl();
  const snackbar = useSnackbar();

  const fileRefs = useRef([]);

  const [currentAction, setCurrentAction] = useState(
    initialNote ? NOTE_ACTION.EDIT : NOTE_ACTION.ADD,
  );
  const [note, setNote] = useState(initialNote);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { attachments = [] } = note || { attachments: [] };

  const attachmentToFile =
    attachments.map(attachmentItem => {
      // eslint-disable-next-line no-param-reassign
      attachmentItem.id = nanoid(8);
      return attachmentItem;
    }) || [];

  const [files, setFiles] = useState(attachmentToFile);

  const saveNote = async (claimNo, noteText, noteId = null) => {
    if (currentAction === NOTE_ACTION.ADD) {
      const apiUrl = `/claims/v1/claim/${claimNumber}/note`;
      const result = await createOne(apiUrl, { note: noteText });
      return result?.data || false;
    }
    if (currentAction === NOTE_ACTION.EDIT) {
      const apiUrl = `/claims/v1/claim/${claimNo}/note/${noteId}/operation/update`;
      const result = await updateOne(apiUrl, { note: noteText });
      return result?.data || false;
    }
    return false;
  };

  const showSuccessMessage = () => {
    snackbar.showMessage(
      formatMessage(
        {
          id:
            currentAction === NOTE_ACTION.ADD
              ? 'NEW_ADDED_TO_CLAIM_X'
              : 'CLAIM_X_NOTE_UPDATED',
          defaultMessage:
            currentAction === NOTE_ACTION.ADD
              ? 'New note added to claim {claimNumber}'
              : 'Claim {claimNumber} note updated',
        },
        { claimNumber },
      ),
    );
  };

  const getErrorMessage = () =>
    formatMessage({
      id:
        currentAction === NOTE_ACTION.ADD
          ? 'FAILED_TO_CREATE_NEW_NOTE'
          : 'FAILED_TO_UPDATE_NOTE',
      defaultMessage:
        currentAction === NOTE_ACTION.ADD
          ? 'Failed to create a new note'
          : 'Failed to update the note',
    });

  const onSubmitSuccess = async data => {
    setError(null);
    setLoading(true);
    const noteReceived = await saveNote(claimNumber, data.note, note?.id);
    if (noteReceived) {
      const result = await Promise.all(
        fileRefs?.current?.map(async fileRef => {
          if (fileRef) {
            return fileRef.submit(noteReceived);
          }
          // Sometimes fileRef tends to be null
          return true;
        }),
      );
      showSuccessMessage();
      const allSuccess = result.every(r => r === true);
      if (!allSuccess) {
        setError(
          formatMessage({
            id: 'ATTACHMENTS_COULD_NOT_BE_SAVED',
            description: 'Some attachments could not be saved',
          }),
        );
      }
      if (currentAction === NOTE_ACTION.ADD) {
        setNote(noteReceived);
        setCurrentAction(NOTE_ACTION.EDIT);
      }
      onNoteChanged();
    } else {
      setError(getErrorMessage());
    }
    setLoading(false);
  };

  const { handleSubmit, control, trigger } = useForm({
    context: 'NoteForm',
    defaultValues: {
      note: note?.note || '',
    },
  });

  const onSubmitError = () => {
    setLoading(false);
  };

  const addFile = () => {
    setFiles(prevData => [
      ...prevData,
      {
        id: nanoid(8),
      },
    ]);
  };

  const removeAttachment = fileId => {
    const newFiles = files.filter(f => f.id !== fileId);
    setFiles([...newFiles]);
  };

  const getFiles = useMemo(
    () =>
      files.map((file, index) => (
        <Box key={file.id} mb={1}>
          <FileView
            reference={ref => {
              fileRefs.current[index] = ref;
            }}
            removeAttachment={removeAttachment}
            id={file?.id}
            claimNumber={claimNumber}
            noteId={note?.id}
            attachmentId={file?.attachmentId}
            attachmentType={file?.attachmentType}
            description={file?.fileDescription}
            fileName={file?.fileName}
            onNoteChanged={onNoteChanged}
            uploadedBy={file?.uploadedBy || null}
            AppModule={AppModule}
          />
          <Divider />
        </Box>
      )),
    [files],
  );

  return (
    <Modal
      PaperProps={{ sx: { width: '100%' } }}
      handleClose={() => setOpen(false)}
      maxWidth="lg"
      title={formatMessage({
        id: currentAction === NOTE_ACTION.ADD ? 'CREATE_NOTE' : 'EDIT_NOTE',
        defaultMessage:
          currentAction === NOTE_ACTION.ADD ? 'Create Note' : 'Edit Note',
      })}
      open
      Icon={NoteIcon}
      actions={[
        {
          color: 'primary',
          variant: 'contained',
          children: formatMessage({
            id: 'SAVE_BUTTON',
            defaultMessage: 'Save',
          }),
          type: 'button',
          onClick: async () => {
            const noteValidation = await trigger();
            let attachmentValidation = true;
            await Promise.all(
              fileRefs.current.map(async fileRef => {
                if (fileRef) {
                  const valid = await fileRef.trigger();
                  if (!valid) {
                    attachmentValidation = false;
                  }
                }
              }),
            );

            if (noteValidation && attachmentValidation) {
              handleSubmit(
                data => {
                  onSubmitSuccess(data);
                },
                data => {
                  onSubmitError(data);
                },
              )();
            }
          },
        },
        {
          color: 'secondary',
          variant: 'contained',
          children: formatMessage({
            id: 'CANCEL_BUTTON',
            defaultMessage: 'Cancel',
          }),
          type: 'button',
          onClick: () => setOpen(false),
        },
      ]}>
      {error && (
        <SpecialSection mb={2} color="error.main">
          <Typography color="error">{error}</Typography>
        </SpecialSection>
      )}
      {loading ? (
        <LoadingEvent>
          <FormattedMessage id="SAVING_NOTE" defaultMessage="Saving Note" />
        </LoadingEvent>
      ) : (
        <Box>
          <Box mb={2} textAlign="right">
            <Button
              sx={{ textTransform: 'none' }}
              onClick={() => addFile()}
              size="small"
              variant="contained"
              startIcon={<AttachFile />}>
              <FormattedMessage
                id="ADD_ATTACHMENT"
                defaultMessage="Add Attachment"
              />
            </Button>
          </Box>
          <Box>
            <Controller
              rules={{
                validate: v => v.trim() !== '' || 'Required',
                required: {
                  value: true,
                  message: formatMessage({
                    id: 'REQUIRED',
                    description: 'Required',
                  }),
                },
                maxLength: {
                  value: MAX_NOTE_LENGTH,
                  message: formatMessage(
                    {
                      id: 'ENTER_LESS_THAN_X_CHAR',
                      description:
                        'Please enter less than {maxNoteLength} characters',
                    },
                    {
                      maxNoteLength: MAX_NOTE_LENGTH,
                    },
                  ),
                },
              }}
              sx={{ mt: 2 }}
              control={control}
              name="note"
              render={({ field, fieldState }) => (
                <div>
                  <FormControl fullWidth>
                    <InputLabel
                      {...(fieldState?.error?.message && {
                        error: !!fieldState?.error?.message,
                      })}>
                      <FormattedMessage id="NOTE" defaultMessage="Note" />
                      <Required show />
                    </InputLabel>
                    <TextField
                      error={!!fieldState?.error?.message}
                      multiline
                      rows={5}
                      {...field}
                    />
                  </FormControl>
                  <FormHelperText
                    sx={{ fontSize: '1em', ml: 2 }}
                    {...(fieldState?.error?.message && {
                      error: !!fieldState?.error?.message,
                    })}>
                    {fieldState?.error?.message
                      ? fieldState?.error?.message
                      : ''}
                  </FormHelperText>
                </div>
              )}
            />
          </Box>
        </Box>
      )}
      <Box>
        {files?.length > 0 && (
          <Grid2 mt={2} container spacing={2}>
            <Grid2 xs={2}>
              <FormLabel>
                <FormattedMessage id="FILE" defaultMessage="File" />
                <Required show />
              </FormLabel>
            </Grid2>
            <Grid2 xs={4}>
              <FormLabel>
                <FormattedMessage
                  id="ATTACHMENT_TYPE"
                  defaultMessage="Attachment Type"
                />
                <Required show />
              </FormLabel>
            </Grid2>
            {Array.isArray(note?.attachments) &&
              note?.attachments?.length > 0 && (
                <Grid2 xs={3}>
                  <FormLabel>
                    <FormattedMessage
                      id="UPLOADED_BY"
                      defaultMessage="Uploaded By"
                    />
                  </FormLabel>
                </Grid2>
              )}
            <Grid2 xs>&nbsp;</Grid2>
            <Grid2 xs>&nbsp;</Grid2>
          </Grid2>
        )}
        {getFiles}
      </Box>
    </Modal>
  );
}

export default NoteModal;
