import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormattedMessage, useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import { useEffect, useImperativeHandle, useState } from 'react';
import { DialogTitle, FormHelperText, Typography } from '@mui/material';
import {
  LoadingEvent,
  Required,
  TextField,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

function UnderwriterApproveClaimModal({ reference, claimId, AppModule }) {
  const { Utils } = AppModule;
  const { ErrorMessage } = Utils;
  const snackbar = useSnackbar();
  const { updateOne } = useDataProvider();
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveReasonText, setApproveReasonText] = useState('');
  const [errors, setErrors] = useState(false);

  const showError = err => {
    snackbar.showMessage({
      type: 'error',
      data:
        err?.message ||
        formatMessage({
          id: 'ERROR_COULD_NOT_APPROVE_CLAIM',
          defaultMessage: 'Error: Could not approve the claim',
        }),
    });
  };

  const onChange = async value => {
    setErrors(false);
    let isValid = false;

    if (!value) {
      setOpen(false);
    } else if (approveReasonText && approveReasonText.trim().length > 0) {
      isValid = true;
    } else {
      setErrors(true);
    }

    if (isValid) {
      try {
        const result = await updateOne(
          `/claims/v1/claim/${claimId}/operation/approveforunderwriter`,
          {
            approveReasonText,
          },
        );
        if (result?.data) {
          snackbar.showMessage(
            formatMessage({
              id: 'CLAIM_APPROVED_SUCCESSFULLY',
              defaultMessage: 'Claim was approved successfully',
            }),
          );
          window.location.reload();
        } else {
          showError(ErrorMessage(result));
        }
      } catch (err) {
        showError(err);
      }
    }
  };

  useImperativeHandle(reference, () => ({
    setOpen(value) {
      setOpen(value);
    },
  }));

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setErrors(false);
      setApproveReasonText('');
    }
  }, [open]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 400,
        bgcolor: 'background.paper',
      }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        open={open}>
        {!loading && (
          <DialogTitle style={{ padding: '8px 20px 30px', fontSize: '18px' }}>
            <FormattedMessage
              id="UNDERWRITER_APPROVE_CLAIM"
              defaultMessage="Underwriter Approve Claim"
            />
          </DialogTitle>
        )}
        {loading ? (
          <DialogContent>
            <LoadingEvent>
              <FormattedMessage
                id="APPROVING_CLAIM"
                defaultMessage="Approving Claim..."
              />
            </LoadingEvent>
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid2 container spacing={2} flexDirection="column">
              <Grid2>
                <Typography mb={1}>
                  <FormattedMessage
                    id="TYPE_IN_APPROVAL_REASON"
                    defaultMessage="Please type in a approval reason"
                  />
                  <Required show />
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  value={approveReasonText}
                  onChange={e => {
                    setApproveReasonText(e.target.value);
                    if (e.target.value) {
                      setErrors(false);
                    }
                  }}
                />
                {errors && (
                  <FormHelperText style={{ fontSize: '14px' }} error>
                    <FormattedMessage id="REQUIRED" defaultMessage="Required" />
                  </FormHelperText>
                )}
              </Grid2>
            </Grid2>
          </DialogContent>
        )}
        {!loading ? (
          <DialogActions
            style={{
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <Button variant="contained" onClick={() => onChange(true)}>
              <FormattedMessage id="APPROVE_BUTTON" defaultMessage="Approve" />
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '20px' }}
              onClick={() => onChange(false)}>
              <FormattedMessage id="CANCEL_BUTTON" defaultMessage="Cancel" />
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </Box>
  );
}

export default UnderwriterApproveClaimModal;
