function getColorFromString(arr, value) {
  const keyNames = value.split('.');
  let data = arr;

  for (let i = 0; i < keyNames.length; i += 1) {
    data = data[keyNames[i]];
  }
  return data;
}

export default {
  getColorFromString,
};
