import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  LoadingEvent,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AuditTable from './AuditTable';

function ClaimStatusFeed({ claimNumber, AppModule }) {
  const { formatMessage } = useIntl();
  const defaultMessage = formatMessage({
    id: 'NO_DATA_EXISTS',
    defaultMessage: 'No data exists',
  });

  const { getOne } = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await getOne(
        `/claims/v1/claim/${claimNumber}/audit/statushistory`,
      );
      if (result) {
        setTableData(result?.data?.claimStatusHistory || []);
      }
      setLoading(false);
    };

    if (claimNumber) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [claimNumber]);

  return (
    <Grid2>
      {loading ? (
        <LoadingEvent>
          <FormattedMessage id="FETCHING_DATA" defaultMessage="Fetching Data" />
        </LoadingEvent>
      ) : (
        <AuditTable
          data={tableData}
          defaultMessage={defaultMessage}
          AppModule={AppModule}
        />
      )}
    </Grid2>
  );
}

export default ClaimStatusFeed;
