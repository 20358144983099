import {
  Avatar,
  Button,
  Divider,
  Menu,
  Typography,
  useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';
import MenuItem from '../../core/MenuItem';

function UserMenu({
  menuItems = [],
  user = { firstName: '', lastName: '', avatar: '', subText: '' },
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        onClick={handleClick}
        sx={{ textTransform: 'none' }}>
        <Grid2 container spacing={1}>
          <Grid2>
            <Avatar
              {...(user?.avatar && { src: `${user.avatar}` })}
              sx={{ bgcolor: theme.palette.primary.main }}
              alt={`${user.firstName} ${user.lastName}`}>
              {user.firstName[0]}
              {user.lastName[0]}
            </Avatar>
          </Grid2>
          <Grid2 textAlign="start">
            <Typography
              variant="body1"
              sx={{ color: theme.palette.tertiary.main }}>
              {user.firstName} {user.lastName}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.secondary.dark }}>
              {user.subText}
            </Typography>
          </Grid2>
        </Grid2>
      </Button>
      <Menu
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '200px',
            borderRadius: '15px',
            border: `1px solid ${theme.palette.secondary.light}`,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {menuItems.map((item, index) => (
          <div key={item.label}>
            <MenuItem
              Icon={item.icon}
              IconProps={{
                sx: { width: '20px', color: theme.palette.tertiary.main },
              }}
              onClick={item.onClick}>
              <Typography variant="subtitle2">{item.label}</Typography>
            </MenuItem>
            {index < menuItems.length - 1 && (
              <Divider sx={{ margin: theme.spacing(2) }} />
            )}
          </div>
        ))}
      </Menu>
    </div>
  );
}

export default UserMenu;
