const MasterDataUtil = {
  getSAById(masterData, saId) {
    if (saId) {
      return masterData?.SERVICE_ADMINISTRATORS?.find(
        item => item?.value === saId,
      );
    }
    return null;
  },

  getSPById(masterData, spId) {
    if (spId) {
      return masterData?.SERVICE_PROVIDERS?.find(item => item?.value === spId);
    }
    return null;
  },

  getCountryById(masterData, countryId) {
    if (countryId) {
      return masterData?.COUNTRIES?.find(item => item?.value === countryId);
    }
    return null;
  },

  getIndustryById(masterData, industryId) {
    if (industryId) {
      return masterData?.INDUSTRIES?.find(item => item?.value === industryId);
    }
    return null;
  },

  getWarrantyTypeId(masterData, warrantyTypeId) {
    if (warrantyTypeId) {
      return masterData?.WARRANTY_TYPES?.find(
        item => item?.value === warrantyTypeId,
      );
    }
    return null;
  },

  getSAClientById(masterData, saClient) {
    if (saClient) {
      return masterData?.SERVICE_ADMINISTRATOR_CLIENT?.find(
        item => item?.value === saClient,
      );
    }
    return null;
  },

  getServiceTypeById(masterData, serviceTypeId) {
    if (serviceTypeId) {
      return masterData?.SERVICE_TYPES?.find(
        item => item?.value === serviceTypeId,
      );
    }
    return null;
  },
};

export default MasterDataUtil;
