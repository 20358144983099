import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useImperativeHandle, useState } from 'react';
import { DialogTitle, FormHelperText, Typography } from '@mui/material';
import {
  LoadingEvent,
  Required,
  Select,
  TextField,
  useDataProvider,
  useSnackbar,
  useStore,
} from '@servicexcelerator/ui-design-system';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

function RejectClaimModal({ reference, claimId, AppModule }) {
  const defaultErrors = {
    rejectCode: false,
    rejectText: false,
  };
  const { Utils } = AppModule;
  const { ErrorMessage } = Utils;
  const { formatMessage } = useIntl();
  const store = useStore();
  const snackbar = useSnackbar();
  const { updateOne } = useDataProvider();
  const masterData = store.domain.masterData.get();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectCode, setRejectCode] = useState('');
  const [rejectText, setRejectText] = useState('');
  const [errors, setErrors] = useState(defaultErrors);

  const REJECT_CODE_OTHER = 'R999';

  const showError = err => {
    snackbar.showMessage({
      type: 'error',
      data:
        err?.message ||
        formatMessage({
          id: 'ERROR_COULD_NOT_CANCEL_CLAIM',
          defaultMessage: 'Error: Could not cancel the claim',
        }),
    });
  };

  const onChange = async value => {
    setErrors(defaultErrors);
    let isValid = false;
    if (!value) {
      setOpen(false);
    } else if (rejectCode) {
      if (rejectCode === REJECT_CODE_OTHER) {
        if (rejectText) {
          isValid = true;
        } else {
          setErrors({
            ...errors,
            rejectText: true,
          });
        }
      } else {
        isValid = true;
      }
    } else {
      setErrors({
        ...errors,
        rejectCode: true,
      });
    }
    if (isValid) {
      try {
        const result = await updateOne(
          `/claims/v1/claim/${claimId}/operation/reject`,
          {
            rejectCode,
            rejectText,
          },
        );
        if (result?.data) {
          snackbar.showMessage(
            formatMessage({
              id: 'CLAIM_REJECTED_SUCCESSFULLY',
              defaultMessage: 'Claim was rejected successfully',
            }),
          );
          window.location.reload();
        } else {
          showError(ErrorMessage(result));
        }
      } catch (err) {
        showError(err);
      }
    }
  };

  useImperativeHandle(reference, () => ({
    setOpen(value) {
      setOpen(value);
    },
  }));

  useEffect(() => {
    if (!open) {
      setLoading(false);
      setErrors(defaultErrors);
      setRejectCode('');
      setRejectText('');
    }
  }, [open]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 400,
        bgcolor: 'background.paper',
      }}>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        open={open}>
        {!loading && (
          <DialogTitle style={{ padding: '8px 20px 30px', fontSize: '18px' }}>
            <FormattedMessage id="REJECT_CLAIM" defaultMessage="Reject Claim" />
          </DialogTitle>
        )}
        {loading ? (
          <DialogContent>
            <LoadingEvent>
              <FormattedMessage
                id="REJECTING_CLAIM"
                defaultMessage="Rejecting Claim..."
              />
            </LoadingEvent>
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid2 container spacing={2} flexDirection="column">
              <Grid2>
                <Typography mb={1}>
                  <FormattedMessage
                    id="SELECT_REJECT_REASON"
                    defaultMessage="Please select the reject reason"
                  />
                  <Required show />
                </Typography>
                <Select
                  defaultValue={rejectCode}
                  onChange={e => {
                    setRejectCode(e.value);
                    if (e.value) {
                      setErrors({ ...errors, rejectCode: false });
                    }
                  }}
                  options={masterData.CLAIM_REJECT_CODES}
                />
                {errors.rejectCode && (
                  <FormHelperText style={{ fontSize: '14px' }} error>
                    <FormattedMessage id="REQUIRED" defaultMessage="Required" />
                  </FormHelperText>
                )}
              </Grid2>
              {rejectCode === REJECT_CODE_OTHER && (
                <Grid2>
                  <Typography mb={1}>
                    <FormattedMessage
                      id="TYPE_IN_REJECT_REASON"
                      defaultMessage="Please type in a reject reason"
                    />
                    <Required show />
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={rejectText}
                    onChange={e => {
                      setRejectText(e.target.value);
                      if (e.target.value) {
                        setErrors({ ...errors, rejectText: false });
                      }
                    }}
                  />
                  {errors.rejectText && (
                    <FormHelperText style={{ fontSize: '14px' }} error>
                      <FormattedMessage
                        id="REQUIRED"
                        defaultMessage="Required"
                      />
                    </FormHelperText>
                  )}
                </Grid2>
              )}
            </Grid2>
          </DialogContent>
        )}
        {!loading ? (
          <DialogActions
            style={{
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <Button variant="contained" onClick={() => onChange(true)}>
              <FormattedMessage id="REJECT_BUTTON" defaultMessage="Reject" />
            </Button>
            <Button
              variant="outlined"
              style={{ marginLeft: '20px' }}
              onClick={() => onChange(false)}>
              <FormattedMessage id="CANCEL_BUTTON" defaultMessage="Cancel" />
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </Box>
  );
}

export default RejectClaimModal;
