import { SimpleTable, useStore } from '@servicexcelerator/ui-design-system';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';

function PaymentSimpleTableControl(props) {
  const {
    formId,
    lessAmountPrepaidColumnIndex = 7,
    AppModule,
    ...rest
  } = props;

  const { Components, Constants } = AppModule;
  const { useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;

  const userAccess = useUserAccess();
  const store = useStore();
  const [columnsIndexToHide, setColumnsIndexToHide] = useState([]);

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  // Header row is excluded, so index is 2
  const rowsIndexToHide = isSA ? [] : [2];

  useEffect(() => {
    if (!store.domain.formState.formState[formId]?.prepaidAmount) {
      setColumnsIndexToHide([lessAmountPrepaidColumnIndex]);
    } else {
      setColumnsIndexToHide([]);
    }
  }, [store.domain.formState.formState[formId]?.prepaidAmount]);

  const simpleTable = useMemo(
    () => (
      <SimpleTable
        rowsIndexToHide={rowsIndexToHide}
        columnsIndexToHide={columnsIndexToHide}
        formId={formId}
        {...rest}
      />
    ),
    [columnsIndexToHide],
  );

  return simpleTable;
}
export default observer(PaymentSimpleTableControl);
