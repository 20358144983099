import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import { useIntl } from 'react-intl';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ClaimStatusFeed from './ClaimStatusFeed';
import ClaimAuditFeed from './ClaimAuditFeed';
import EntitlementFeed from './EntitlementFeed';
import ImportFeed from './ImportFeed';
import ClaimRuleResultFeed from './ClaimRuleResultFeed';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

function ClaimFeeds(props) {
  const { claimNumber, formId, hideTabs = [], AppModule } = props;
  const { Components, Constants } = AppModule;
  const { useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;

  const { formatMessage } = useIntl();
  const [value, setValue] = useState(0);

  const userAccess = useUserAccess();

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid2 xs={12}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            {!hideTabs.includes('CLAIM_STATUS_FEED') && (
              <Tab
                label={formatMessage({
                  id: 'CLAIM_STATUS_FEED',
                  defaultMessage: 'Status Audit',
                })}
                {...a11yProps(0)}
                value={0}
              />
            )}
            {!hideTabs.includes('AUDIT_DATA') && (
              <Tab
                label={formatMessage({
                  id: 'AUDIT_DATA',
                  defaultMessage: 'Audit Data',
                })}
                {...a11yProps(1)}
                value={1}
              />
            )}
            {isSA && !hideTabs.includes('ENTITLEMENT_RECORD') && (
              <Tab
                label={formatMessage({
                  id: 'ENTITLEMENT_RECORD',
                  defaultMessage: 'Entitlement Record',
                })}
                {...a11yProps(2)}
                value={2}
              />
            )}
            {!hideTabs.includes('IMPORTED_RECORD') && (
              <Tab
                label={formatMessage({
                  id: 'IMPORTED_RECORD',
                  defaultMessage: 'Imported Record',
                })}
                {...a11yProps(3)}
                value={3}
              />
            )}
            {isSA && !hideTabs.includes('EVENT_FEED') && (
              <Tab
                label={formatMessage({
                  id: 'EVENT_FEED',
                  defaultMessage: 'Event Feed',
                })}
                {...a11yProps(4)}
                value={4}
              />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ClaimStatusFeed claimNumber={claimNumber} AppModule={AppModule} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ClaimAuditFeed
            claimNumber={claimNumber}
            formId={formId}
            AppModule={AppModule}
          />
        </CustomTabPanel>
        {isSA && (
          <CustomTabPanel value={value} index={2}>
            <EntitlementFeed claimNumber={claimNumber} />
          </CustomTabPanel>
        )}
        <CustomTabPanel value={value} index={3}>
          <ImportFeed claimNumber={claimNumber} />
        </CustomTabPanel>
        {isSA && (
          <CustomTabPanel value={value} index={4}>
            <ClaimRuleResultFeed
              claimNumber={claimNumber}
              AppModule={AppModule}
            />
          </CustomTabPanel>
        )}
      </Box>
    </Grid2>
  );
}

export default ClaimFeeds;
