import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  AsyncSelectFormControl,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

function Cities({ defaultCountryCode, defaultStateCode, ...props }) {
  const { getMany } = useDataProvider();
  const store = useStore();
  const { formId } = props;

  const [location, setLocation] = useState({
    countryCode: defaultCountryCode,
    stateCode: defaultStateCode,
  });

  const getCities = async (input, loc) => {
    if (loc.countryCode && loc.stateCode) {
      const result = await getMany(
        `/claims/v1/location/${loc.countryCode}/${loc.stateCode}/cities`,
      );
      return result?.data || [];
    }
    return [];
  };

  useEffect(() => {
    const cc =
      store.domain.formState.getState(formId)?.customer?.customerAddress
        ?.addressCountryCode;
    const sc =
      store.domain.formState.getState(formId)?.customer?.customerAddress
        ?.addressState;

    setLocation({
      countryCode: cc,
      stateCode: sc,
    });
  }, [
    store.domain.formState.formState[formId]?.customer?.customerAddress
      ?.addressCountryCode,
    store.domain.formState.formState[formId]?.customer?.customerAddress
      ?.addressState,
  ]);

  return (
    <Grid2 xs>
      <AsyncSelectFormControl
        key={JSON.stringify(location)}
        optionTypeFlatArray
        optionLabelKey="label"
        optionValueKey="value"
        fullWidth
        fetchOptions={input => getCities(input, location)}
        fetchOptionsById={input => getCities(input, location)}
        {...props}
      />
    </Grid2>
  );
}

export default observer(Cities);
