import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';

function getBaseClaimButtons({
  formSubmitting,
  setFormSubmitting,
  formRef,
  claimId,
  snackbar,
  confirmationPopUpRef,
  updateOne,
  rejectClaimModalRef,
  underwriteApproveClaimModalRef,
  underwriteRejectClaimModalRef,
  approveClaimModalRef,
  formMode,
  navigate,
  claimStatus,
  formatMessage = () => undefined,
  AppModule,
}) {
  const { Utils, Icons } = AppModule;
  const {
    ApprovedClaim,
    CancelledClaim,
    CloseButton,
    DuplicateClaimButton,
    EditButton,
    RejectedClaim,
    ReOpenClaimButton,
    ResetButton,
    SaveButton,
    SubmitButton,
  } = Icons;
  const { ErrorMessage } = Utils;

  return {
    SUBMIT: {
      async: true,
      Icon: SubmitButton,
      children: formatMessage({
        id: 'SUBMIT_BUTTON',
        defaultMessage: 'Submit',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        setFormSubmitting(true);
        formRef.current.submitForm('submit');
      },
    },
    SAVE: {
      async: true,
      Icon: SaveButton,
      children: formatMessage({
        id: 'SAVE_BUTTON',
        defaultMessage: 'Save',
      }),
      processingLabel: formatMessage({
        id: 'SAVING',
        defaultMessage: 'saving...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        setFormSubmitting(true);
        formRef.current.submitForm('saveonly');
      },
    },
    EDIT: {
      async: true,
      Icon: EditButton,
      children: formatMessage({
        id: 'EDIT_BUTTON',
        defaultMessage: 'Edit',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        navigate(`/claim/edit/${claimId}`);
      },
    },
    DUPLICATE: {
      async: true,
      Icon: DuplicateClaimButton,
      children: formatMessage({
        id: 'COPY_BUTTON',
        defaultMessage: 'Copy',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        const showError = err => {
          snackbar.showMessage({
            type: 'error',
            data:
              err?.message ||
              formatMessage({
                id: 'ERROR_COULD_NOT_CREATE_CLAIM_COPY',
                defaultMessage: 'Error: Could not create a copy of the claim',
              }),
          });
        };
        confirmationPopUpRef.current.init({
          message: formatMessage({
            id: 'DO_YOU_WANT_TO_CREATE_CLAIM_COPY',
            defaultMessage: 'Do you want to create a copy of this claim?',
          }),
          positiveButtonText: formatMessage({
            id: 'YES',
            defaultMessage: 'Yes',
          }),
          negativeButtonText: formatMessage({
            id: 'CANCEL',
            defaultMessage: 'Cancel',
          }),
          loadingMessage: formatMessage({
            id: 'CREATING_CLAIM_COPY',
            defaultMessage: 'Creating a copy of the claim...',
          }),
          title: formatMessage({
            id: 'COPY_CLAIM',
            defaultMessage: 'Copy Claim',
          }),
          onChange: async value => {
            if (value) {
              confirmationPopUpRef.current.setLoading(true);
              try {
                const result = await updateOne(
                  `/claims/v1/claim/${claimId}/operation/copy`,
                );
                if (result?.data) {
                  snackbar.showMessage(
                    formatMessage(
                      {
                        id: 'NEW_CLAIM_X_CREATED',
                        defaultMessage:
                          'New claim was created with claim id {claimNumber}',
                      },
                      { claimNumber: result?.data?.claimNumber },
                    ),
                  );
                  navigate(`/claim/edit/${result?.data?.claimNumber}`);
                } else {
                  showError(ErrorMessage(result));
                }
              } catch (err) {
                showError(err);
              }
            }
            confirmationPopUpRef.current.setOpen(false);
          },
        });
        confirmationPopUpRef.current.setOpen(true);
      },
    },
    REOPEN: {
      async: true,
      Icon: ReOpenClaimButton,
      children: formatMessage({
        id: 'REOPEN_BUTTON',
        defaultMessage: 'Reopen',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        const showError = err => {
          snackbar.showMessage({
            type: 'error',
            data:
              err?.message ||
              formatMessage({
                id: 'ERROR_COULD_NOT_REOPEN_CLAIM',
                defaultMessage: 'Error: Could not reopen the claim',
              }),
          });
        };
        confirmationPopUpRef.current.init({
          message: formatMessage({
            id: 'DO_YOU_WANT_TO_REOPEN_CLAIM',
            defaultMessage: 'Do you want to reopen this claim?',
          }),
          positiveButtonText: formatMessage({
            id: 'YES',
            defaultMessage: 'Yes',
          }),
          negativeButtonText: formatMessage({
            id: 'NO',
            defaultMessage: 'No',
          }),
          loadingMessage: formatMessage({
            id: 'REOPENING_CLAIM',
            defaultMessage: 'Reopening claim...',
          }),
          title: formatMessage({
            id: 'REOPEN_CLAIM',
            defaultMessage: 'Reopen Claim',
          }),
          onChange: async value => {
            if (value) {
              confirmationPopUpRef.current.setLoading(true);
              try {
                const result = await updateOne(
                  `/claims/v1/claim/${claimId}/operation/reopen`,
                );
                if (result?.data && result?.data?.claimNumber) {
                  if (claimStatus === 'REJ') {
                    snackbar.showMessage(
                      formatMessage(
                        {
                          id: 'NEW_CLAIM_X_CREATED_SUCCESSFULLY',
                          defaultMessage:
                            'New Claim {claimNumber} was created successfully',
                        },
                        {
                          claimNumber: result?.data?.claimNumber,
                        },
                      ),
                    );
                    navigate(`/claim/edit/${result?.data?.claimNumber}`);
                  } else {
                    snackbar.showMessage(
                      formatMessage(
                        {
                          id: 'CLAIM_X_REOPENED_SUCCESSFULLY',
                          defaultMessage:
                            'Claim {claimId} was reopened successfully',
                        },
                        {
                          claimId,
                        },
                      ),
                    );
                    window.location.reload();
                  }
                } else {
                  showError(ErrorMessage(result));
                }
              } catch (err) {
                showError(err);
              }
            }
            confirmationPopUpRef.current.setOpen(false);
          },
        });
        confirmationPopUpRef.current.setOpen(true);
      },
    },
    APPROVE: {
      async: true,
      Icon: ApprovedClaim,
      children: formatMessage({
        id: 'APPROVE_BUTTON',
        defaultMessage: 'Approve',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        approveClaimModalRef.current.setOpen(true);
      },
    },
    UNDERWRITER_APPROVE: {
      async: true,
      Icon: ApprovedClaim,
      children: formatMessage({
        id: 'UNDERWRITER_APPROVE_BUTTON',
        defaultMessage: 'Underwriter Approve',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        underwriteApproveClaimModalRef.current.setOpen(true);
      },
    },
    REJECT: {
      async: true,
      Icon: RejectedClaim,
      children: formatMessage({
        id: 'REJECT_BUTTON',
        defaultMessage: 'Reject',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        rejectClaimModalRef.current.setOpen(true);
      },
    },
    UNDERWRITER_REJECT: {
      async: true,
      Icon: RejectedClaim,
      children: formatMessage({
        id: 'UNDERWRITER_REJECT_BUTTON',
        defaultMessage: 'Underwriter Reject',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        underwriteRejectClaimModalRef.current.setOpen(true);
      },
    },
    CANCEL: {
      async: true,
      Icon: CancelledClaim,
      children: formatMessage({
        id: 'CANCEL_CLAIM_BUTTON',
        defaultMessage: 'Cancel Claim',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        const showError = err => {
          snackbar.showMessage({
            type: 'error',
            data:
              err?.message ||
              formatMessage({
                id: 'ERROR_COULD_NOT_CANCEL_CLAIM',
                defaultMessage: 'Error: Could not cancel the claim',
              }),
          });
        };
        confirmationPopUpRef.current.init({
          message: formatMessage({
            id: 'DO_YOU_WANT_TO_CANCEL_CLAIM',
            defaultMessage: 'Do you want to cancel this claim?',
          }),
          positiveButtonText: formatMessage({
            id: 'YES',
            defaultMessage: 'Yes',
          }),
          negativeButtonText: formatMessage({
            id: 'NO',
            defaultMessage: 'No',
          }),
          loadingMessage: formatMessage({
            id: 'CANCELLING_CLAIM',
            defaultMessage: 'Cancelling claim...',
          }),
          title: formatMessage({
            id: 'CANCEL_CLAIM',
            defaultMessage: 'Cancel Claim',
          }),
          onChange: async value => {
            if (value) {
              confirmationPopUpRef.current.setLoading(true);
              try {
                const result = await updateOne(
                  `/claims/v1/claim/${claimId}/operation/cancel`,
                );
                if (result?.data) {
                  snackbar.showMessage(
                    formatMessage({
                      id: 'CLAIM_CANCELLED_SUCCESSFULLY',
                      defaultMessage: 'Claim was cancelled successfully',
                    }),
                  );
                  window.location.reload();
                } else {
                  showError(ErrorMessage(result));
                }
              } catch (err) {
                showError(err);
              }
            }
            confirmationPopUpRef.current.setOpen(false);
          },
        });
        confirmationPopUpRef.current.setOpen(true);
      },
    },
    CLOSE: {
      async: true,
      Icon: CloseButton,
      children: formatMessage({
        id: 'CLOSE_BUTTON',
        defaultMessage: 'Close',
      }),
      processingLabel: '',
      inProcess: formSubmitting,
      onClick: () => {
        if (formMode === DYNAMIC_FORM_MODE.CREATE) {
          navigate('/claims/search');
        } else {
          navigate(`/claim/view/${claimId}`);
        }
      },
    },
    RESET: {
      async: true,
      Icon: ResetButton,
      children: formatMessage({
        id: 'RESET_BUTTON',
        defaultMessage: 'Reset',
      }),
      processingLabel: formatMessage({
        id: 'PROCESSING',
        defaultMessage: 'Processing...',
      }),
      inProcess: formSubmitting,
      onClick: () => {
        formRef.current.reset();
      },
    },
  };
}

export default getBaseClaimButtons;
