import { FormControl, InputLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FormValue } from '@servicexcelerator/ui-design-system';

import { FormattedMessage } from 'react-intl';

function RepeatRepairClaimIdControl(props) {
  const { localeId, label, getValues } = props;

  if (!getValues('repeatRepair')) {
    return null;
  }

  return (
    <Grid2 xs p={2}>
      <FormControl fullWidth>
        <InputLabel>
          <FormattedMessage id={localeId} defaultMessage={label} />
        </InputLabel>

        <FormValue>{getValues('repeatRepairClaimId')}</FormValue>
      </FormControl>
    </Grid2>
  );
}

export default RepeatRepairClaimIdControl;
