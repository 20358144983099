import uiSchema from './index.json';
import uiSchemaCustomer from './customer_section.json';
import uiSchemaProduct from './product_section.json';
import uiSchemaService from './service_section.json';
import uiSchemaBasicInfo from './basic_info.json';
import uiSchemaPayment from './payment_section.json';
import uiAdditionalInfo from './additional_section.json';
import uiNotesAttachment from './notesattachment_section.json';
import createAjv from './ajv/create.json';
import updateAjv from './ajv/update.json';

const getUISchema = () => {
  uiSchema['ui:children'] = [
    uiSchemaBasicInfo,
    uiSchemaCustomer,
    uiSchemaProduct,
    uiSchemaService,
    uiSchemaPayment,
    uiNotesAttachment,
    uiAdditionalInfo,
  ];
  return uiSchema;
};

const getAjvSchema = () => ({
  create: createAjv,
  update: updateAjv,
});

const schema = {
  ui: getUISchema(),
  ajv: getAjvSchema(),
};

export default schema;
