import { FormattedMessage, useIntl } from 'react-intl';
import { Box, FormLabel, Chip } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  FormValue,
  HeaderTitle,
  useStore,
} from '@servicexcelerator/ui-design-system';

function ClaimsHeader({ claimData = null, AppModule }) {
  const { Components, Utils, Constants, Icons } = AppModule;
  const { Claim } = Icons;
  const { toLongDate, MasterDataUtil } = Utils;
  const { useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;
  const { formatMessage } = useIntl();
  const userAccess = useUserAccess();
  const store = useStore();
  const masterData = store.domain.masterData.get();

  const sa = MasterDataUtil.getSAById(
    masterData,
    claimData?.serviceAdministrator,
  );

  const sp = MasterDataUtil.getSPById(
    masterData,
    claimData?.serviceProvider?.serviceProviderNumber,
  );

  return (
    <Grid2 container alignItems="center" spacing={4}>
      <Grid2 sx={{ mr: '20px' }}>
        <HeaderTitle Icon={Claim}>
          <FormattedMessage id="CLAIM" defaultMessage="Claim" />
        </HeaderTitle>
      </Grid2>
      {claimData?.claimNumber && (
        <Grid2>
          <Box>
            <FormLabel>
              {formatMessage({
                id: 'CLAIM_NUMBER',
                defaultMessage: 'Claim Number',
              })}
            </FormLabel>
          </Box>
          <Box>
            <FormValue>{claimData?.claimNumber}</FormValue>
          </Box>
          <Box>
            <Chip
              size="small"
              sx={{ fontSize: '12px', fontWeight: '600' }}
              label={claimData?.claimStatus?.value}
              color="warning"
            />
          </Box>
          {claimData?.claimStatusDateTime && (
            <Box>
              <FormLabel>
                {toLongDate(claimData?.claimStatusDateTime)}
              </FormLabel>
            </Box>
          )}
        </Grid2>
      )}
      {claimData?.serviceAdministrator && (
        <Grid2>
          <Box>
            <FormLabel>
              {formatMessage({
                id: 'SERVICE_ADMINISTRATOR',
                defaultMessage: 'Service Administrator',
              })}
            </FormLabel>
          </Box>
          <Box>
            <FormValue>{sa?.label}</FormValue>
          </Box>
        </Grid2>
      )}
      {claimData?.serviceProvider?.serviceProviderNumber &&
        userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR && (
          <Grid2>
            <Box>
              <FormLabel>
                {formatMessage({
                  id: 'SERVICE_PROVIDER',
                  defaultMessage: 'Service Provider',
                })}
              </FormLabel>
            </Box>
            <Box>
              <FormValue>{sp?.label}</FormValue>
            </Box>
          </Grid2>
        )}
      {claimData?.serviceAdministratorClientName && (
        <Grid2>
          <Box>
            <FormLabel>
              {formatMessage({
                id: 'CLIENT',
                defaultMessage: 'Client',
              })}
            </FormLabel>
          </Box>
          <Box>
            <FormValue>{claimData?.serviceAdministratorClientName}</FormValue>
          </Box>
        </Grid2>
      )}
    </Grid2>
  );
}
export default ClaimsHeader;
