import { Quiz } from '@mui/icons-material';

const getSteps = (formatMessage, claimFormId, AppModule) => {
  const { Icons } = AppModule;
  const {
    BasicInfo: BasicInfoIcon,
    Customer,
    NotesAttachment,
    Payment,
    Product,
    Service,
    Parts,
  } = Icons;

  const STEPS = {
    BASIC_INFO: {
      icon: BasicInfoIcon,
      label: formatMessage({
        id: 'BASIC_INFO',
        defaultMessage: 'Basic Info',
      }),
      id: 'stepBasicInfo',
    },
    CUSTOMER: {
      icon: Customer,
      label: formatMessage({
        id: 'CUSTOMER',
        defaultMessage: 'Customer',
      }),
      id: 'stepCustomer',
    },
    PRODUCT: {
      icon: Product,
      label: formatMessage({
        id: 'PRODUCT',
        defaultMessage: 'Product',
      }),
      id: 'stepProduct',
    },
    SERVICE: {
      icon: Service,
      label: formatMessage({
        id: 'SERVICE',
        defaultMessage: 'Service',
      }),
      id: 'stepService',
    },
    PAYMENT: {
      icon: Payment,
      label: formatMessage({
        id: 'PAYMENT',
        defaultMessage: 'Payment',
      }),
      id: 'stepPayment',
    },
    NOTES_AND_ATTACHMENTS: {
      icon: NotesAttachment,
      label: formatMessage({
        id: 'NOTES',
        defaultMessage: 'Notes/Attachment',
      }),
      id: 'stepNotesAttachment',
    },
    ADDITIONAL_INFO: {
      icon: Quiz,
      label: formatMessage({
        id: 'ADDITIONAL_INFO',
        defaultMessage: 'Additional Info',
      }),
      id: 'stepAdditionalInfo',
    },
    PARTS: {
      icon: Parts,
      label: formatMessage({
        id: 'PARTS',
        defaultMessage: 'Parts',
      }),
      id: 'stepParts',
    },
  };
  if (claimFormId) {
    switch (claimFormId) {
      case 'ELECTROLUX-2023':
        return [
          STEPS.BASIC_INFO,
          STEPS.CUSTOMER,
          STEPS.PRODUCT,
          STEPS.SERVICE,
          STEPS.PAYMENT,
          STEPS.NOTES_AND_ATTACHMENTS,
          STEPS.ADDITIONAL_INFO,
        ];
      case 'FOODLOSS-2023':
        return [
          STEPS.BASIC_INFO,
          STEPS.CUSTOMER,
          STEPS.PRODUCT,
          STEPS.SERVICE,
          STEPS.PAYMENT,
          STEPS.NOTES_AND_ATTACHMENTS,
          STEPS.ADDITIONAL_INFO,
        ];
      case 'BASICOEM-2023':
        return [
          STEPS.BASIC_INFO,
          STEPS.CUSTOMER,
          STEPS.PRODUCT,
          STEPS.SERVICE,
          STEPS.PARTS,
          STEPS.PAYMENT,
          STEPS.NOTES_AND_ATTACHMENTS,
          STEPS.ADDITIONAL_INFO,
        ];
      case 'BASICHVAC-2023':
        return [
          STEPS.BASIC_INFO,
          STEPS.CUSTOMER,
          STEPS.PRODUCT,
          STEPS.SERVICE,
          STEPS.PARTS,
          STEPS.PAYMENT,
          STEPS.NOTES_AND_ATTACHMENTS,
          STEPS.ADDITIONAL_INFO,
        ];
      case 'BASICCONTRACT-2023':
        return [
          STEPS.BASIC_INFO,
          STEPS.CUSTOMER,
          STEPS.PRODUCT,
          STEPS.SERVICE,
          STEPS.PARTS,
          STEPS.PAYMENT,
          STEPS.NOTES_AND_ATTACHMENTS,
          STEPS.ADDITIONAL_INFO,
        ];
      default:
        return [];
    }
  }
  return [];
};

export default getSteps;
