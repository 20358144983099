import { SESSION_STORAGE_KEYCLOAK_CONFIG } from '@constants/index';
import { PageLoading } from '@servicexcelerator/ui-design-system';
import getDomain from '@utils/GetDomain';
import { useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';

function AuthWrapper({ children, defaultKeycloakConfig }) {
  const [keycloakParams, setKeycloakParams] = useState(defaultKeycloakConfig);
  const [initializing, setInitializing] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchAuthParams() {
      let cacheSucceeded = false;
      // Check if cached version is available
      const cacheConfigStr = window.sessionStorage.getItem(
        SESSION_STORAGE_KEYCLOAK_CONFIG,
      );
      if (cacheConfigStr) {
        const cacheConfigObj = JSON.parse(cacheConfigStr);
        if (cacheConfigObj && cacheConfigObj?.realm) {
          setKeycloakParams(cacheConfigObj);
          cacheSucceeded = true;
        }
      }

      if (!cacheSucceeded) {
        const apiUrl = `${getDomain('api')}/claims/v1/auth/config`;
        const data = await fetch(apiUrl);
        const params = await data.json();
        if (params.data) {
          const keycloakParamFromApi = {
            ...defaultKeycloakConfig,
            ...params.data,
          };
          window.sessionStorage.setItem(
            SESSION_STORAGE_KEYCLOAK_CONFIG,
            JSON.stringify(keycloakParamFromApi),
          );
          setKeycloakParams(keycloakParamFromApi);
        } else {
          setError(true);
        }
      }

      setInitializing(false);
    }

    fetchAuthParams();
  }, []);

  if (initializing) {
    return <PageLoading>Fetching Auth Params</PageLoading>;
  }

  if (error) {
    return 'Unable to fetch keycloak params';
  }

  return <AuthProvider {...keycloakParams}>{children}</AuthProvider>;
}

export default AuthWrapper;
