import { lazy } from 'react';
import { createBrowserRouter, useRouteError } from 'react-router-dom';
import ProtectedRoute from '@components/Routing/ProtectedRoute';
import LazyLoadRoute from '@components/Routing/LazyLoadRoute';
import * as AppModule from '@modules/AppModule';
import ErrorPage, { ERROR_PAGE_TYPE } from '@pages/ErrorPage';

const Login = LazyLoadRoute(lazy(() => import('@pages/Login')));
const Dashboard = LazyLoadRoute(lazy(() => import('@pages/Dashboard')));

function BubbleRouteError() {
  const error = useRouteError();
  return <ErrorPage codeErrors={{ message: error?.message }} />;
}

const routes = [
  {
    path: '/',
    element: <Login />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/unauthorized',
    element: <ErrorPage type={ERROR_PAGE_TYPE.FORBIDDEN} />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/servicedown',
    element: <ErrorPage type={ERROR_PAGE_TYPE.SERVICE_DOWN} />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/servererror',
    element: <ErrorPage type={ERROR_PAGE_TYPE.SERVER_ERROR} />,
    errorElement: <BubbleRouteError />,
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute
        Component={Dashboard}
        componentProps={{
          AppModule,
        }}
      />
    ),

    errorElement: <BubbleRouteError />,
  },
  {
    path: '*',
    element: <ErrorPage type={ERROR_PAGE_TYPE.NOT_FOUND} />,
    errorElement: <BubbleRouteError />,
  },
];

export const getRoutesFromModule = modules => {
  let tmpRoutes = [];
  modules.every(item => {
    if (item?.routes) {
      const moduleRoutes = item?.routes({
        ProtectedRoute,
        BubbleRouteError,
        AppModule,
      });
      tmpRoutes = [...tmpRoutes, ...moduleRoutes];
    }
    return true;
  });
  return tmpRoutes;
};

const injectAdditionalRoutes = (existingRoutes, additionalRoutes = []) => {
  const updatedRouteInfo = existingRoutes;
  // Add other routes before catch all route
  const positionToAdd = routes.length - 1;
  if (additionalRoutes.length > 0) {
    updatedRouteInfo.splice(positionToAdd, 0, ...additionalRoutes);
  }

  return updatedRouteInfo;
};

const getRouter = additionalRoutes => {
  const allRoutes = injectAdditionalRoutes(routes, additionalRoutes);
  return createBrowserRouter(allRoutes, {
    basename: process.env.PUBLIC_URL,
  });
};

export default getRouter;
