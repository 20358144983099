import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '@servicexcelerator/ui-design-system';

export function getClaimPartCalculated(currentPartRow) {
  let calculatedValue = null;
  if (currentPartRow) {
    const {
      quantity,
      partCost: { amountRequested: partCostAmount = null } = {
        amountRequested: null,
      },
      handlingFee: { amountRequested: partsHandlingAmount = null } = {
        amountRequested: null,
      },
      partPrePaid = false,
    } = currentPartRow;

    if (partPrePaid) {
      return 0;
    }

    if (
      (quantity === 0 || quantity) &&
      (partCostAmount === 0 || partCostAmount)
    ) {
      calculatedValue = Number(quantity) * Number(partCostAmount);
    }

    if (partsHandlingAmount === 0 || partsHandlingAmount) {
      if (calculatedValue === null) {
        calculatedValue = Number(partsHandlingAmount);
      } else {
        calculatedValue += Number(partsHandlingAmount);
      }
    }
  }
  return calculatedValue;
}

function setTotalPartsInPaymentSection({ currentValues, setValue }) {
  let totalParts = 0;
  const claimParts = currentValues?.claimParts;
  if (Array.isArray(claimParts)) {
    totalParts = claimParts.reduce((acc, claimPart) => {
      const calculatedTotal = getClaimPartCalculated(claimPart);
      if (calculatedTotal) {
        return acc + calculatedTotal;
      }
      return acc;
    }, 0);
    setValue('claimPayment.totalParts.amountRequested', Number(totalParts));
  }
}

function PartsTotalWithPartsManager(props) {
  const { formId, setValue, AppModule } = props;
  const { Constants } = AppModule;
  const { CLAIM_STATUS } = Constants;

  const store = useStore();

  useEffect(() => {
    const currentValues = store.domain.formState.getState(formId);

    if (currentValues?.claimStatus?.key === CLAIM_STATUS.INC) {
      setTotalPartsInPaymentSection({
        currentValues,
        setValue,
      });
    }
  }, [JSON.stringify(store.domain.formState.formState[formId]?.claimParts)]);

  return null;
}

export default observer(PartsTotalWithPartsManager);
