import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  FormJsonSchemaUtil,
  FormValue,
  useStore,
} from '@servicexcelerator/ui-design-system';

const paymentItems = type => {
  const result = [
    {
      'html:name': `claimPayment.labor.${type}`,
    },
    {
      'html:name': `claimPayment.freightAndShipping.${type}`,
    },
    {
      'html:name': `claimPayment.travel.${type}`,
    },
    {
      'html:name': `claimPayment.totalParts.${type}`,
    },
    {
      'html:name': `claimPayment.partsHandling.${type}`,
    },
    {
      'html:name': `claimPayment.tax.${type}`,
    },
    {
      'html:name': `claimPayment.other.${type}`,
    },
    {
      'html:name': `claimPayment.refrigerant.${type}`,
    },
    {
      'html:name': `claimPayment.refrigerantReclamation.${type}`,
    },
  ];
  return result;
};

function Sum(schemaItems, formValues) {
  return schemaItems.reduce((acc, item) => {
    const formElementName = item['html:name'];
    const value = parseFloat(
      FormJsonSchemaUtil.getValueFromObject(formValues, formElementName) || 0,
    );
    return parseFloat(acc + value);
  }, 0);
}

function getCalculatedTotal(currentValues, paymentType) {
  const prePaidAmount = Number(currentValues?.prepaidAmount) || 0;
  let calculatedValue = Sum(paymentItems(paymentType), currentValues);
  calculatedValue = Number(calculatedValue);
  if (prePaidAmount) {
    calculatedValue -= prePaidAmount;
  }
  return calculatedValue;
}

function PaymentSummaryCalculated(props) {
  const { formId, paymentType, decimal = 0, claimData, AppModule } = props;

  const { Constants } = AppModule;
  const { CLAIM_STATUS } = Constants;

  const [result, setResult] = useState(null);
  const store = useStore();

  useEffect(() => {
    const currentValues = store.domain.formState.getState(formId);
    let calculatedValue = null;
    if (paymentType === 'amountRequested') {
      if (currentValues?.claimStatus?.key === CLAIM_STATUS.INC) {
        calculatedValue = getCalculatedTotal(currentValues, paymentType);
      } else {
        calculatedValue = claimData?.amountRequested;
      }
    } else if (paymentType === 'amountApproved') {
      if (currentValues?.claimStatus?.key === CLAIM_STATUS.PAR) {
        calculatedValue = getCalculatedTotal(currentValues, paymentType);
      } else {
        calculatedValue = claimData?.amountApproved;
      }
    }
    if (calculatedValue || calculatedValue === 0) {
      const withDecimalValue = Number.isNaN(calculatedValue)
        ? calculatedValue
        : parseFloat(calculatedValue).toFixed(decimal);
      setResult(`$${withDecimalValue}`);
    } else {
      setResult('');
    }
  }, [
    store.domain.formState.formState[formId]?.prepaidAmount,
    store.domain.formState.formState[formId]?.claimPayment?.labor
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment?.freightAndShipping
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment?.travel
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment?.totalParts
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment?.partsHandling
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment?.tax
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment?.other
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment?.labor
      ?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.freightAndShipping
      ?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.travel
      ?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.totalParts
      ?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.partsHandling
      ?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.tax?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.other
      ?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.refrigerant
      ?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment?.refrigerant
      ?.amountRequested,
    store.domain.formState.formState[formId]?.claimPayment
      ?.refrigerantReclamation?.amountApproved,
    store.domain.formState.formState[formId]?.claimPayment
      ?.refrigerantReclamation?.amountRequested,
  ]);

  return <FormValue>{result}</FormValue>;
}

export default observer(PaymentSummaryCalculated);
