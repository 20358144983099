import { Link } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ReadMore } from '@servicexcelerator/ui-design-system';

function CustomerAddress(props) {
  const {
    row: {
      original: {
        addressStreet,
        addressCity,
        addressState,
        addressPostalCode,
      } = {
        addressStreet: '',
        addressCity: '',
        addressState: '',
        addressPostalCode: '',
      },
    },
  } = props;

  const cityStateZip = [];

  if (addressCity) {
    cityStateZip.push(addressCity);
  }
  if (addressState) {
    cityStateZip.push(addressState);
  }
  if (addressPostalCode) {
    cityStateZip.push(addressPostalCode);
  }

  return (
    <>
      {addressStreet && <div>{addressStreet}</div>}
      {cityStateZip.length > 0 && <div>{cityStateZip.join(', ')}</div>}
    </>
  );
}

function MediumDateDisplay(props, fieldName, AppModule) {
  const { Utils } = AppModule;
  const { toMediumDate } = Utils;

  const {
    row: { original },
  } = props;

  return original[fieldName] ? toMediumDate(original[fieldName]) : '';
}

function ClaimNumber(props) {
  const {
    row: {
      original: { claimNumber },
    },
  } = props;
  return <Link to={`/claim/edit/${claimNumber}`}>{claimNumber}</Link>;
}

function IconClaimStatus(props, AppModule) {
  const {
    row: {
      original: {
        claimStatus: { key, value } = { key: null, value: 'UnKnown' },
      },
    },
  } = props;
  const { Components } = AppModule;
  const { ClaimStatusIcon } = Components;

  return (
    <Grid2 sx={{ display: 'inline-flex' }} alignItems="center">
      <ClaimStatusIcon statusId={key} sx={{ marginRight: '5px' }} />
      {value}
    </Grid2>
  );
}

function CustomerComplaint(props, formatMessage) {
  const {
    row: {
      original: { customerComplaint = '' },
    },
  } = props;
  return (
    <ReadMore
      showReadLess
      readMoreText={formatMessage({
        id: 'READ_MORE',
        defaultMessage: 'read more',
      })}
      showLessText={formatMessage({
        id: 'SHOW_LESS',
        defaultMessage: 'show less',
      })}
      charsToShow={30}>
      {customerComplaint}
    </ReadMore>
  );
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'claimNumberLink',
    header: formatMessage({
      id: 'CLAIM_NUMBER',
      defaultMessage: 'Claim Number',
    }),
    Cell: ClaimNumber,
    enableHiding: false,
  },
  {
    accessorKey: 'claimStatus',
    header: formatMessage({
      id: 'STATUS',
      defaultMessage: 'Status',
    }),
    Cell: props => IconClaimStatus(props, AppModule),
    enableColumnActions: false,
    enableColumnResizing: false,
    size: 75,
  },
  {
    accessorKey: 'serviceCompletionDate',
    header: formatMessage({
      id: 'DATE_OF_SERVICE',
      defaultMessage: 'Date of Service',
    }),
    Cell: props => MediumDateDisplay(props, 'serviceCompletionDate', AppModule),
  },
  {
    accessorKey: 'customerName',
    header: formatMessage({
      id: 'CUSTOMER',
      defaultMessage: 'Customer',
    }),
  },
  {
    accessorKey: 'serviceAdministratorName',
    header: formatMessage({
      id: 'SERVICE_ADMINISTRATOR',
      defaultMessage: 'Service Administrator',
    }),
  },
  {
    accessorKey: 'serviceProviderName',
    header: formatMessage({
      id: 'SERVICE_PROVIDER_NAME',
      defaultMessage: 'Service Provider Name',
    }),
  },
  {
    accessorKey: 'serviceProviderNumber',
    header: formatMessage({
      id: 'SERVICE_PROVIDER_NUMBER',
      defaultMessage: 'Service Provider Number',
    }),
  },
  {
    accessorKey: 'serviceRequestedDate',
    header: formatMessage({
      id: 'JOB_INITIATED_DATE',
      defaultMessage: 'Job Initiated Date',
    }),
    Cell: props => MediumDateDisplay(props, 'serviceRequestedDate', AppModule),
  },
  {
    accessorKey: 'customerAddress',
    header: formatMessage({
      id: 'ADDRESS',
      defaultMessage: 'Address',
    }),
    Cell: CustomerAddress,
  },
  {
    accessorKey: 'productType',
    header: formatMessage({
      id: 'PRODUCT_TYPE',
      defaultMessage: 'Product Type',
    }),
  },
  {
    accessorKey: 'brandName',
    header: formatMessage({
      id: 'BRAND',
      defaultMessage: 'Brand',
    }),
  },
  {
    accessorKey: 'claimStatusDateTime',
    header: formatMessage({
      id: 'LAST_UPDATED',
      defaultMessage: 'Last Updated',
    }),
    Cell: props => MediumDateDisplay(props, 'claimStatusDateTime', AppModule),
  },
  {
    accessorKey: 'customerComplaint',
    header: formatMessage({
      id: 'CUSTOMER_COMPLAINT',
      defaultMessage: 'Customer Complaint',
    }),
    Cell: props => CustomerComplaint(props, formatMessage),
  },
  {
    accessorKey: 'serviceAdministratorJobNumber',
    header: formatMessage({
      id: 'SERVICE_ADMINISTRATOR_JOB_NUMBER',
      defaultMessage: 'Job Number (Service Administrator)',
    }),
  },
  {
    accessorKey: 'serviceProviderJobNumber',
    header: formatMessage({
      id: 'SERVICE_PROVIDER_JOB_NUMBER',
      defaultMessage: 'Job Number (Service Provider)',
    }),
  },
];

export default getColumns;
