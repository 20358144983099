import { Typography } from '@mui/material';

const getClaimReviews = (reviewErrors, AppModule) => {
  const { Utils } = AppModule;
  const { ErrorMessage } = Utils;
  if (reviewErrors) {
    if (Array.isArray(reviewErrors) && reviewErrors.length > 0) {
      return reviewErrors?.map(item => (
        <li key={JSON.stringify(item)} style={{ marginBottom: '5px' }}>
          <Typography color="error">
            {ErrorMessage(item.reviewReason)}
          </Typography>
        </li>
      ));
    }
  }
  return null;
};

export default getClaimReviews;
