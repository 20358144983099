import { FormatterUtil } from '@servicexcelerator/ui-design-system';
import { FormattedMessage } from 'react-intl';

function LongDateDisplay(props, fieldName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils } = AppModule;
  const { toLongDate } = Utils;

  return toLongDate(original[fieldName]);
}

function UserDetails(props) {
  const {
    row: {
      original: { createdBy = null },
    },
  } = props;

  const { firstName, lastName } = createdBy || {
    firstName: '',
    lastName: '',
  };

  return `${firstName} ${lastName}`;
}

function GetValue(props, propName, AppModule) {
  const {
    row: { original },
  } = props;
  const { Utils, Constants } = AppModule;
  const { toDate } = Utils;
  const { FIELD_TYPES } = Constants;

  const { id, type, format } = original;
  const fieldValue = original[propName];

  if (propName === 'id') {
    return id;
  }

  if (!fieldValue) {
    return '';
  }

  if (type === FIELD_TYPES.AMOUNT) {
    return parseFloat(fieldValue).toFixed(2);
  }

  if (type === FIELD_TYPES.PHONE) {
    return FormatterUtil.formatPhoneNumber(fieldValue, format);
  }

  if (type === FIELD_TYPES.PHONE_TYPE) {
    return fieldValue.value;
  }

  if (type === FIELD_TYPES.DATE) {
    return toDate(fieldValue, format);
  }

  return fieldValue;
}

const getColumns = (formatMessage, AppModule) => [
  {
    accessorKey: 'fieldName',
    header: formatMessage({
      id: 'AUDIT_FIELD_NAME',
      defaultMessage: 'Field Name',
    }),
    Cell: props => (
      <FormattedMessage
        id={GetValue(props, 'id', AppModule)}
        defaultMessage={GetValue(props, 'id', AppModule)}
      />
    ),
  },
  {
    accessorKey: 'oldValue',
    header: formatMessage({
      id: 'AUDIT_OLD_VALUE',
      defaultMessage: 'Old Value',
    }),
    Cell: props => GetValue(props, 'oldValue', AppModule),
  },
  {
    accessorKey: 'newValue',
    header: formatMessage({
      id: 'AUDIT_NEW_VALUE',
      defaultMessage: 'New Value',
    }),
    Cell: props => GetValue(props, 'newValue', AppModule),
  },
  {
    accessorKey: 'statusChangeByUser',
    header: formatMessage({
      id: 'USER',
      defaultMessage: 'User',
    }),
    Cell: props => UserDetails(props),
  },
  {
    accessorKey: 'createdDateTime',
    header: formatMessage({
      id: 'CLAIM_STATUS_DATE_TIME',
      defaultMessage: 'Date',
    }),
    Cell: props => LongDateDisplay(props, 'createdDateTime', AppModule),
  },
];

export default getColumns;
