import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';

function getButtonsForServiceAdministrator({
  CLAIM_BUTTONS,
  formMode,
  claimStatus,
  userAccess,
  AppModule,
}) {
  const { Components, Constants } = AppModule;
  const { hasPermission } = Components;
  const { CLAIM_STATUS, PERMISSIONS } = Constants;
  let buttonSetResult = [];
  const permissions = userAccess?.permissions || {};

  switch (claimStatus) {
    case CLAIM_STATUS.SUB:
      buttonSetResult = [];
      break;

    case CLAIM_STATUS.INC: {
      if (
        formMode === DYNAMIC_FORM_MODE.VIEW &&
        hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)
      ) {
        buttonSetResult = [
          CLAIM_BUTTONS.CANCEL,
          CLAIM_BUTTONS.DUPLICATE,
          CLAIM_BUTTONS.EDIT,
        ];
      } else if (
        formMode === DYNAMIC_FORM_MODE.CREATE &&
        hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)
      ) {
        buttonSetResult = [
          CLAIM_BUTTONS.RESET,
          CLAIM_BUTTONS.SAVE,
          CLAIM_BUTTONS.SUBMIT,
        ];
      } else if (hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)) {
        buttonSetResult = [
          CLAIM_BUTTONS.CANCEL,
          CLAIM_BUTTONS.DUPLICATE,
          CLAIM_BUTTONS.RESET,
          CLAIM_BUTTONS.SAVE,
          CLAIM_BUTTONS.SUBMIT,
        ];
      }
      break;
    }
    case CLAIM_STATUS.CAN: {
      if (hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)) {
        buttonSetResult = [CLAIM_BUTTONS.REOPEN, CLAIM_BUTTONS.DUPLICATE];
      }
      break;
    }
    case CLAIM_STATUS.PAR: {
      if (hasPermission(PERMISSIONS.ADJUDICATE_CLAIMS, permissions)) {
        buttonSetResult = [CLAIM_BUTTONS.REJECT, CLAIM_BUTTONS.APPROVE];
      }
      if (hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)) {
        buttonSetResult.push(CLAIM_BUTTONS.DUPLICATE);
      }
      break;
    }

    case CLAIM_STATUS.PUR:
    case CLAIM_STATUS.PWR: {
      if (hasPermission(PERMISSIONS.UNDERWRITER_APPROVE, permissions)) {
        buttonSetResult.push(CLAIM_BUTTONS.UNDERWRITER_APPROVE);
      }
      if (hasPermission(PERMISSIONS.UNDERWRITER_REJECT, permissions)) {
        buttonSetResult.push(CLAIM_BUTTONS.UNDERWRITER_REJECT);
      }
      if (hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)) {
        buttonSetResult.push(CLAIM_BUTTONS.DUPLICATE);
      }
      break;
    }
    case CLAIM_STATUS.APP: {
      if (hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)) {
        buttonSetResult.push(CLAIM_BUTTONS.DUPLICATE);
      }
      break;
    }
    case CLAIM_STATUS.REJ: {
      if (hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)) {
        buttonSetResult = [CLAIM_BUTTONS.REOPEN, CLAIM_BUTTONS.DUPLICATE];
      }
      break;
    }
    case CLAIM_STATUS.COR:
    case CLAIM_STATUS.PAD:
    case CLAIM_STATUS.TRX: {
      if (hasPermission(PERMISSIONS.MODIFY_CLAIMS, permissions)) {
        buttonSetResult = [CLAIM_BUTTONS.DUPLICATE];
      }
      break;
    }
    default:
      buttonSetResult = [];
  }
  return buttonSetResult;
}

export default getButtonsForServiceAdministrator;
