import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  ConfirmationPopUp,
  LoggerUtil,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { MaterialReactTable } from 'material-react-table';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import columns from './lib/columns';

function ListNotes({
  claimNumber,
  onNoteSelected = () => undefined,
  onDeleteNote = () => undefined,
  canModifyNote = false,
  AppModule,
}) {
  const { formatMessage } = useIntl();
  const { getMany, getApiUrl } = useDataProvider();
  const confirmationPopUpRef = useRef();
  const store = useStore();

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  // table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  useEffect(() => {
    async function fetchData() {
      if (claimNumber) {
        if (!data.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }

        const url = new URL(
          `${getApiUrl()}/claims/v1/claim/${claimNumber}/note`,
        );

        url.searchParams.set('page', `${pagination.pageIndex + 1}`);
        url.searchParams.set('limit', `${pagination.pageSize}`);

        // TODO: Add filters once ready
        url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        url.searchParams.set('globalFilter', globalFilter ?? '');
        url.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        try {
          const response = await getMany(url.href);
          if (response?.data && response?.data?.notes) {
            const responseData = response.data;
            setData(responseData?.notes);
            setRowCount(responseData?.pagination?.totalItems);
          }
        } catch (error) {
          setIsError(true);
          LoggerUtil.log('Notes Error', error);
          return;
        }
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    }

    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  return (
    <Grid2 xs={12} mt={2}>
      <ConfirmationPopUp reference={confirmationPopUpRef} />
      <MaterialReactTable
        localization={store.domain.locale.getForMuiTable()}
        columns={columns(
          onNoteSelected,
          onDeleteNote,
          canModifyNote,
          confirmationPopUpRef,
          AppModule,
        )}
        data={data || []}
        getRowId={row => row.id}
        initialState={{ showColumnFilters: false }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: formatMessage({
                  id: 'ERROR_LOADING_DATA',
                  defaultMessage: 'Error loading data',
                }),
              }
            : undefined
        }
        manualFiltering
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount || 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        muiTablePaperProps={{ elevation: 0 }}
        enableColumnFilters={false}
        enableTopToolbar={false}
        enableBottomToolbar={rowCount > 0}
        enableTableHead={false}
      />
    </Grid2>
  );
}

export default ListNotes;
